import { createAction, props } from '@ngrx/store';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { LineOfBusinessSubtypeFilterModel } from 'app/models/api/line-of-business-subtype-filter-model';
import { SearchResponse } from 'app/models/api/search-response';
import { LineOfBusinessSubtypeSearchModel } from 'app/models/api/line-of-business-subtype-search-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { LineOfBusinessSubtypeModel } from 'app/models/api/line-of-business-subtype-model';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { LineOfBusinessSubtypeOptionModel } from 'app/models/api/line-of-business-subtype-option-model';
import { LineOfBusinessSubtypeOptionFilterModel } from 'app/models/api/line-of-business-subtype-option-filter-model';

export enum LineOfBusinessSubtypeActions {
	LoadLineOfBusinessSubtypes = '[LineOfBusinessSubtype] LoadLineOfBusinessSubtypes',
	LineOfBusinessSubtypesLoaded = '[LineOfBusinessSubtype] LineOfBusinessSubtypesLoaded',

	CreateLineOfBusinessSubtype = '[LineOfBusinessSubtype] CreateLineOfBusinessSubtype',
	LineOfBusinessSubtypeCreated = '[LineOfBusinessSubtype] LineOfBusinessSubtypeCreated',

	PreLoadLineOfBusinessSubtype = '[LineOfBusinessSubtype] PreLoadLineOfBusinessSubtype',
	PreLoadLineOfBusinessSubtypeNotFound = '[LineOfBusinessSubtype] PreLoadLineOfBusinessSubtypeNotFound',
	LineOfBusinessSubtypePreLoaded = '[LineOfBusinessSubtype] LineOfBusinessSubtypePreLoaded',

	UpdateLineOfBusinessSubtype = '[LineOfBusinessSubtype] UpdateLineOfBusinessSubtype',
	LineOfBusinessSubtypeUpdated = '[LineOfBusinessSubtype] LineOfBusinessSubtypeUpdated',

	DeleteLineOfBusinessSubtype = '[LineOfBusinessSubtype] DeleteLineOfBusinessSubtype',
	LineOfBusinessSubtypeDeleted = '[LineOfBusinessSubtype] LineOfBusinessSubtypeDeleted',

	LoadLineOfBusinessSubtypeOptions = '[LineOfBusinessSubtype] LoadLineOfBusinessSubtypeOptions',
	LineOfBusinessSubtypeOptionsLoaded = '[LineOfBusinessSubtype] LineOfBusinessSubtypeOptionsLoaded',

	ErrorOccurred = '[LineOfBusinessSubtype] ErrorOccurred'
}

export const loadLineOfBusinessSubtypes = createAction(
	LineOfBusinessSubtypeActions.LoadLineOfBusinessSubtypes,
	props<
		ActionRequestPayload<
			FilterSearchRequest<LineOfBusinessSubtypeFilterModel>
		>
	>()
);

export const lineOfBusinessSubtypesLoaded = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypesLoaded,
	props<
		ActionResponsePayload<SearchResponse<LineOfBusinessSubtypeSearchModel>>
	>()
);

export const preLoadLineOfBusinessSubtype = createAction(
	LineOfBusinessSubtypeActions.PreLoadLineOfBusinessSubtype,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadLineOfBusinessSubtypeNotFound = createAction(
	LineOfBusinessSubtypeActions.PreLoadLineOfBusinessSubtypeNotFound
);

export const lineOfBusinessSubtypePreLoaded = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypePreLoaded,
	props<ActionResponsePayload<IPreLoadModel<LineOfBusinessSubtypeModel>>>()
);

export const createLineOfBusinessSubtype = createAction(
	LineOfBusinessSubtypeActions.CreateLineOfBusinessSubtype,
	props<ActionRequestPayload<LineOfBusinessSubtypeModel>>()
);

export const lineOfBusinessSubtypeCreated = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypeCreated
);

export const updateLineOfBusinessSubtype = createAction(
	LineOfBusinessSubtypeActions.UpdateLineOfBusinessSubtype,
	props<ActionRequestPayload<LineOfBusinessSubtypeModel>>()
);

export const lineOfBusinessSubtypeUpdated = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypeUpdated
);

export const deleteLineOfBusinessSubtype = createAction(
	LineOfBusinessSubtypeActions.DeleteLineOfBusinessSubtype,
	props<ActionRequestPayload<number>>()
);

export const lineOfBusinessSubtypeDeleted = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypeDeleted
);

export const loadLineOfBusinessSubtypeOptions = createAction(
	LineOfBusinessSubtypeActions.LoadLineOfBusinessSubtypeOptions,
	props<ActionRequestPayload<LineOfBusinessSubtypeOptionFilterModel>>()
);

export const lineOfBusinessSubtypeOptionsLoaded = createAction(
	LineOfBusinessSubtypeActions.LineOfBusinessSubtypeOptionsLoaded,
	props<ActionResponsePayload<LineOfBusinessSubtypeOptionModel[]>>()
);

export const errorOccurred = createAction(
	LineOfBusinessSubtypeActions.ErrorOccurred
);
