import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoPipe } from '@ngneat/transloco';
import { GeneratedFileStatusModel } from 'app/models/api/generated-file-status-model';
import { DialogMenuComponent } from 'app/shared/components/dialog-menu/dialog-menu.component';

@Component({
	selector:        'app-generated-file-button',
	templateUrl:     './generated-file-button.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports:         [
		NgSwitch,
		MatIcon,
		NgSwitchCase,
		TranslocoPipe,
		MatTooltip,
		MatProgressSpinner,
		NgSwitchDefault,
		MatIconButton,
		DialogMenuComponent
	],
	standalone: true
})
export class GeneratedFileButtonComponent {
	@Input() status: GeneratedFileStatusModel;
	@Input() generatedFileId: number;

	@Output() download: EventEmitter<void> = new EventEmitter();
	@Output() delete: EventEmitter<void> = new EventEmitter();

	public readonly GeneratedFileStatusModel = GeneratedFileStatusModel;
}
