<div
	[matMenuTriggerFor]="disabled ? null : menu"
	(menuOpened)="menuOpened.emit()"
	(menuClosed)="menuClosed.emit()"
	[matTooltip]="tooltip | transloco"
	[matTooltipDisabled]="isTooltipDisabled"
	[matTooltipShowDelay]="tooltipShowDelay"
	[ngClass]="{
		'rounded-full': !text,
		'rounded-xl': text
	}"
>
	<!-- Icon Button -->
	<button
		*ngIf="!text"
		mat-icon-button
		type="button"
		[color]="color"
		[ngClass]="ngButtonClass"
		[disabled]="disabled"
		(click)="open($event)"
	>
		<mat-icon
			[color]="color"
			[ngClass]="ngButtonClass"
			fontSet="fas"
			[fontIcon]="icon"
			class="text-lg"
		></mat-icon>
	</button>

	<!-- Text Button -->
	<button
		*ngIf="text"
		mat-stroked-button
		type="button"
		class="bg-card"
		[color]="color"
		[ngClass]="ngButtonClass"
		[disabled]="disabled"
		(click)="open($event)"
	>
		<mat-icon
			*ngIf="icon"
			[color]="color"
			[ngClass]="ngButtonClass"
			fontSet="fas"
			[fontIcon]="icon"
			class="text-lg"
		></mat-icon>
		<span class="pl-1">{{ text | transloco }}</span>
	</button>
</div>

<!-- Menu -->
<mat-menu
	#menu="matMenu"
	class="relative flex h-full w-full flex-col rounded-xl"
>
	<ng-template matMenuContent>
		<div
			(click)="$event.stopPropagation()"
			class="overflow-hidden"
		>
			<!-- Content -->
			<div class="flex flex-auto flex-row items-start px-3 py-2">
				<!-- Icon -->
				<div class="my-0.5 mr-2 flex h-10 w-10 flex-0 items-center justify-center rounded-full bg-warn-100 text-warn-600">
					<mat-icon
						color="warn"
						fontSet="fas"
						fontIcon="fa-triangle-exclamation"
						class="text-2xl"
					></mat-icon>
				</div>

				<div class="flex flex-col items-start text-left">
					<!-- Title -->
					<div
						class="font-medium leading-6"
						[innerHTML]="getTitle | transloco"
					></div>

					<!-- Message -->
					<div
						*ngIf="message"
						class="text-secondary text-sm leading-normal"
						[innerHTML]="translateMessage ? (message | transloco) : message"
					></div>
				</div>
			</div>

			<!-- Actions -->
			<div class="flex items-center justify-between space-x-3 bg-sky-300 px-3 py-2">
				<!-- Cancel -->
				<button
					mat-stroked-button
					class="bg-card"
					(click)="close()"
				>
					<mat-icon
						fontSet="fas"
						fontIcon="fa-xmark"
						class="mr-0.5 text-xl"
					></mat-icon>
					<span>{{ 'actions.cancel' | transloco }}</span>
				</button>

				<!-- Confirm -->
				<button
					mat-stroked-button
					class="bg-card"
					color="primary"
					(click)="confirm($event)"
				>
					<mat-icon
						fontSet="fas"
						fontIcon="fa-check"
						class="mr-0.5 text-xl"
					></mat-icon>
					<span>{{ 'actions.confirm' | transloco }}</span>
				</button>
			</div>
		</div>
	</ng-template>
</mat-menu>
