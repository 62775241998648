import { NgClass, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef
} from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationType } from 'app/models/domain/notification/notification-type';
import { NotificationModel } from 'app/models/dto/notification-model';

@Component({
	selector:      'app-snack-bar',
	templateUrl:   './snack-bar.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone:    true,
	imports:       [NgClass, NgSwitch, MatIconModule, NgSwitchCase, MatButtonModule]
})
export class SnackBarComponent {
	public readonly NotificationType = NotificationType;

	constructor(
		public matSnackBarRef: MatSnackBarRef<SnackBarComponent>,
		private readonly transloco: TranslocoService,
		@Inject(MAT_SNACK_BAR_DATA) public data: NotificationModel
	) {}

	public get text(): string {
		return (
			this.data.text
			?? this.transloco.translate(
				this.data.translationPath,
				this.data.params
			)
		);
	}

	public close(): void {
		this.matSnackBarRef.dismiss();
	}
}
