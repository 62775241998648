<div
	[ngClass]="{
		'bg-green-600': data.type === NotificationType.Success,
		'bg-red-600': data.type === NotificationType.Error,
		'bg-orange-600': data.type === NotificationType.Warning,
		'bg-primary-900': data.type === NotificationType.Info
	}"
	class="flex flex-row rounded px-4 py-2 shadow-xl"
>
	<!-- Icon -->
	<div
		class="flex pt-1"
		[ngSwitch]="data.type"
	>
		<mat-icon
			*ngSwitchCase="NotificationType.Success"
			fontSet="fas"
			fontIcon="fa-circle-check"
			class="text-3xl text-white"
		></mat-icon>
		<mat-icon
			*ngSwitchCase="NotificationType.Error"
			fontSet="fas"
			fontIcon="fa-triangle-exclamation"
			class="text-3xl text-white"
		></mat-icon>
		<mat-icon
			*ngSwitchCase="NotificationType.Warning"
			fontSet="fas"
			fontIcon="fa-circle-exclamation"
			class="text-3xl text-white"
		></mat-icon>
		<mat-icon
			*ngSwitchCase="NotificationType.Info"
			fontSet="fas"
			fontIcon="fa-circle-info"
			class="text-3xl text-white"
		></mat-icon>
	</div>

	<!-- Message -->
	<span class="flex w-full items-center px-3 text-lg font-semibold tracking-tight text-white">{{ text }}</span>

	<!-- Close buttons -->
	<button
		mat-icon-button
		class="flex pt-1"
		(click)="close()"
	>
		<mat-icon
			fontSet="fas"
			fontIcon="fa-close"
			class="text-2xl text-white"
		></mat-icon>
	</button>
</div>
