import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { environment } from 'environments/environment';
import * as Sentry from '@sentry/angular';

if (environment.production) {
	enableProdMode();
}

if (environment.sentryDsn) {
	Sentry.init({
		environment:  environment.name,
		release:      environment.releaseVersion,
		dsn:          environment.sentryDsn,
		integrations: [
			Sentry.replayIntegration(),
			Sentry.browserTracingIntegration(),
			Sentry.dedupeIntegration(),
			Sentry.captureConsoleIntegration({ levels: ['error'] })
		],
		tracePropagationTargets:  ['localhost', environment.apiUrl],
		tracesSampleRate:         1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0
	});
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
