import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	NgZone,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { MatBadge } from '@angular/material/badge';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { PayorRoutes } from 'app/common/routes';
import { catchAppError } from 'app/common/utils/utils.error';
import { distinctUntilChanged, interval, map, startWith } from 'rxjs';
import {
	listItemTooltipShowDelay,
	notificationUpdatePeriod
} from 'app/common/app-constants';
import { BaseApiComponent } from 'app/common/base/base-api-component';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { loadCurrentUserNotifications } from 'app/store/account/account.actions';
import { selectNotifications } from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';

@UntilDestroy()
@Component({
	selector:        'app-user-notification',
	templateUrl:     './user-notification.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone:      true,
	imports:         [
		MatIconButton,
		MatIcon,
		MatBadge,
		AsyncPipe,
		MatDivider,
		MatMenu,
		MatMenuItem,
		TranslocoPipe,
		MatMenuTrigger,
		MatButton,
		MatTooltip
	]
})
export class UserNotificationComponent
	extends BaseApiComponent
	implements OnInit
{
	public readonly listItemTooltipShowDelay = listItemTooltipShowDelay;

	public notificationCount: number = 0;
	public showNotifications: boolean;

	constructor(
		private readonly store: Store<IAccountState>,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly ngZone: NgZone,
		private readonly router: Router
	) {
		super();
	}

	ngOnInit(): void {
		this.ngZone.runOutsideAngular(() => {
			interval(notificationUpdatePeriod)
				.pipe(startWith(0), untilDestroyed(this))
				.subscribe(() => {
					this.store.dispatch(
						loadCurrentUserNotifications(
							this.createRequestPayload()
						)
					);
				});
		});

		this.store
			.select(selectNotifications)
			.pipe(
				untilDestroyed(this),
				filterNullOrUndefined(),
				map(x => x.payorsUnreadCount),
				distinctUntilChanged()
			)
			.subscribe(payorCount => {
				this.notificationCount = payorCount;
				this.showNotifications = this.notificationCount > 0;

				this.changeDetectorRef.detectChanges();
			});
	}

	public goToPayors(): void {
		this.router.navigate([PayorRoutes.Root]).catch(catchAppError);
	}
}
