import { createAction, props } from '@ngrx/store';
import { AberrantQuantityModel } from 'app/models/api/aberrant-quantity-model';
import { AberrantQuantitySearchModel } from 'app/models/api/aberrant-quantity-search-model';
import { CreateAberrantQuantityModel } from 'app/models/api/create-aberrant-quantity-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { UpdateAberrantQuantityModel } from 'app/models/api/update-aberrant-quantity-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum AberrantQuantityActions {
	LoadAberrantQuantity = '[AberrantQuantity] LoadAberrantQuantity',
	AberrantQuantityLoaded = '[AberrantQuantity] AberrantQuantityLoaded',

	PreLoadAberrantQuantity = '[AberrantQuantity] PreLoadAberrantQuantity',
	AberrantQuantityPreLoaded = '[AberrantQuantity] AberrantQuantityPreLoaded',
	PreLoadAberrantQuantityNotFound = '[AberrantQuantity] PreLoadAberrantQuantityNotFound',

	LoadAberrantQuantityById = '[AberrantQuantity] LoadAberrantQuantityById',
	AberrantQuantityNotFound = '[AberrantQuantity] AberrantQuantityNotFound',
	AberrantQuantityByIdLoaded = '[AberrantQuantity] AberrantQuantityByIdLoaded',

	CreateAberrantQuantity = '[AberrantQuantity] CreateAberrantQuantity',
	AberrantQuantityCreated = '[AberrantQuantity] AberrantQuantityCreated',

	UpdateAberrantQuantity = '[AberrantQuantity] UpdateAberrantQuantity',
	AberrantQuantityUpdated = '[AberrantQuantity] AberrantQuantityUpdated',

	DeleteAberrantQuantity = '[AberrantQuantity] DeleteAberrantQuantity',
	AberrantQuantityDeleted = '[AberrantQuantity] AberrantQuantityDeleted',

	ErrorOccurred = '[AberrantQuantity] ErrorOccurred'
}

export const loadAberrantQuantityList = createAction(
	AberrantQuantityActions.LoadAberrantQuantity,
	props<ActionRequestPayload<SearchRequest>>()
);

export const aberrantQuantityLoaded = createAction(
	AberrantQuantityActions.AberrantQuantityLoaded,
	props<ActionResponsePayload<SearchResponse<AberrantQuantitySearchModel>>>()
);

export const preLoadAberrantQuantityById = createAction(
	AberrantQuantityActions.PreLoadAberrantQuantity,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadAberrantQuantityNotFound = createAction(
	AberrantQuantityActions.PreLoadAberrantQuantityNotFound
);

export const aberrantQuantityByIdPreLoaded = createAction(
	AberrantQuantityActions.AberrantQuantityPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<AberrantQuantityModel>>>()
);

export const loadAberrantQuantityById = createAction(
	AberrantQuantityActions.LoadAberrantQuantityById,
	props<ActionRequestPayload<number>>()
);

export const loadAberrantQuantityNotFound = createAction(
	AberrantQuantityActions.AberrantQuantityNotFound
);

export const aberrantQuantityByIdLoaded = createAction(
	AberrantQuantityActions.AberrantQuantityByIdLoaded,
	props<ActionResponsePayload<AberrantQuantityModel>>()
);

export const createAberrantQuantity = createAction(
	AberrantQuantityActions.CreateAberrantQuantity,
	props<ActionRequestPayload<CreateAberrantQuantityModel>>()
);

export const aberrantQuantityCreated = createAction(
	AberrantQuantityActions.AberrantQuantityCreated
);

export const updateAberrantQuantity = createAction(
	AberrantQuantityActions.UpdateAberrantQuantity,
	props<ActionRequestPayload<UpdateAberrantQuantityModel>>()
);

export const aberrantQuantityUpdated = createAction(
	AberrantQuantityActions.AberrantQuantityUpdated
);

export const deleteAberrantQuantity = createAction(
	AberrantQuantityActions.DeleteAberrantQuantity,
	props<ActionRequestPayload<number>>()
);

export const aberrantQuantityDeleted = createAction(
	AberrantQuantityActions.AberrantQuantityDeleted
);

export const errorOccurred = createAction(
	AberrantQuantityActions.ErrorOccurred
);
