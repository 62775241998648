import { createAction, props } from '@ngrx/store';
import { ExcludedPharmacyModel } from 'app/models/api/excluded-pharmacy-model';
import { CreateExcludedPharmacyModel } from 'app/models/api/create-excluded-pharmacy-model';
import { UpdateExcludedPharmacyModel } from 'app/models/api/update-excluded-pharmacy-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { BaseSearchColumnFilterModel } from 'app/models/api/base-search-column-filter-model';
import { SearchResponse } from 'app/models/api/search-response';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum ExcludedPharmacyActions {
	LoadExcludedPharmacy = '[ExcludedPharmacy] LoadExcludedPharmacy',
	ExcludedPharmacyLoaded = '[ExcludedPharmacy] ExcludedPharmacyLoaded',

	PreLoadExcludedPharmacy = '[ExcludedPharmacy] PreLoadExcludedPharmacy',
	ExcludedPharmacyPreLoaded = '[ExcludedPharmacy] ExcludedPharmacyPreLoaded',
	PreLoadExcludedPharmacyNotFound = '[ExcludedPharmacy] PreLoadExcludedPharmacyNotFound',

	LoadExcludedPharmacyById = '[ExcludedPharmacy] LoadExcludedPharmacyById',
	LoadExcludedPharmacyNotFound = '[ExcludedPharmacy] LoadExcludedPharmacyNotFound',
	ExcludedPharmacyByIdLoaded = '[ExcludedPharmacy] ExcludedPharmacyByIdLoaded',

	CreateExcludedPharmacy = '[ExcludedPharmacy] CreateExcludedPharmacy',
	ExcludedPharmacyCreated = '[ExcludedPharmacy] ExcludedPharmacyCreated',

	UpdateExcludedPharmacy = '[ExcludedPharmacy] UpdateExcludedPharmacy',
	ExcludedPharmacyUpdated = '[ExcludedPharmacy] ExcludedPharmacyUpdated',

	DeleteExcludedPharmacy = '[ExcludedPharmacy] DeleteExcludedPharmacy',
	ExcludedPharmacyDeleted = '[ExcludedPharmacy] ExcludedPharmacyDeleted',

	LoadExcludedPharmacyOptions = '[ExcludedPharmacy] LoadExcludedPharmacyOptions',
	ExcludedPharmacyOptionsLoaded = '[ExcludedPharmacy] ExcludedPharmacyOptionsLoaded',

	LoadExcludedPharmacyDetailsByNcpdp = '[ExcludedPharmacy] LoadExcludedPharmacyDetailsByNcpdp',
	ExcludedPharmacyDetailsByNcpdpLoaded = '[ExcludedPharmacy] ExcludedPharmacyDetailsByNcpdpLoaded',

	ErrorOccurred = '[ExcludedPharmacy] ErrorOccurred'
}

export const loadExcludedPharmacyList = createAction(
	ExcludedPharmacyActions.LoadExcludedPharmacy,
	props<
		ActionRequestPayload<FilterSearchRequest<BaseSearchColumnFilterModel>>
	>()
);

export const excludedPharmacyLoaded = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyLoaded,
	props<ActionResponsePayload<SearchResponse<ExcludedPharmacyModel>>>()
);

export const preLoadExcludedPharmacyById = createAction(
	ExcludedPharmacyActions.PreLoadExcludedPharmacy,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadExcludedPharmacyNotFound = createAction(
	ExcludedPharmacyActions.PreLoadExcludedPharmacyNotFound
);

export const excludedPharmacyByIdPreLoaded = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<ExcludedPharmacyModel>>>()
);

export const loadExcludedPharmacyById = createAction(
	ExcludedPharmacyActions.LoadExcludedPharmacyById,
	props<ActionRequestPayload<number>>()
);

export const loadExcludedPharmacyNotFound = createAction(
	ExcludedPharmacyActions.LoadExcludedPharmacyNotFound
);

export const excludedPharmacyByIdLoaded = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyByIdLoaded,
	props<ActionResponsePayload<ExcludedPharmacyModel>>()
);

export const createExcludedPharmacy = createAction(
	ExcludedPharmacyActions.CreateExcludedPharmacy,
	props<ActionRequestPayload<CreateExcludedPharmacyModel>>()
);

export const excludedPharmacyCreated = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyCreated
);

export const updateExcludedPharmacy = createAction(
	ExcludedPharmacyActions.UpdateExcludedPharmacy,
	props<ActionRequestPayload<UpdateExcludedPharmacyModel>>()
);

export const excludedPharmacyUpdated = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyUpdated
);

export const deleteExcludedPharmacy = createAction(
	ExcludedPharmacyActions.DeleteExcludedPharmacy,
	props<ActionRequestPayload<number>>()
);

export const excludedPharmacyDeleted = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyDeleted
);

export const loadExcludedPharmacyOptions = createAction(
	ExcludedPharmacyActions.LoadExcludedPharmacyOptions,
	props<ActionRequestPayload<void>>()
);

export const excludedPharmacyOptionsLoaded = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadExcludedPharmacyDetailsByNcpdp = createAction(
	ExcludedPharmacyActions.LoadExcludedPharmacyDetailsByNcpdp,
	props<ActionRequestPayload<string>>()
);

export const excludedPharmacyDetailsByNcpdpLoaded = createAction(
	ExcludedPharmacyActions.ExcludedPharmacyDetailsByNcpdpLoaded,
	props<ActionResponsePayload<ExcludedPharmacyModel>>()
);

export const errorOccurred = createAction(
	ExcludedPharmacyActions.ErrorOccurred
);
