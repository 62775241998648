import {
	HttpErrorResponse,
	HttpEvent,
	HttpEventType,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpStatusCode
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
	exceptionLocalDbStorageName,
	exceptionLocalDbName,
	longMessageNotificationDuration
} from 'app/common/app-constants';
import { MaintenanceRoutes } from 'app/common/routes';
import { ExceptionModel } from 'app/models/dto/exception-model';
import { catchAppError } from 'app/common/utils/utils.error';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { TwrxErrorCode } from 'app/models/api/twrx-error-code';
import { NotificationType } from 'app/models/domain/notification/notification-type';
import { AuthRoutes } from 'app/services/api/api-routes';
import { ToastService } from 'app/services/toast.service';
import { catchError, map, Observable } from 'rxjs';
import { match } from 'ts-pattern';
import { IndexDbService } from 'app/services/index-db.service';
import moment from 'moment';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	private readonly textDecoder: TextDecoder = new TextDecoder();

	constructor(
		private readonly toastService: ToastService,
		private readonly indexDbService: IndexDbService,
		private readonly router: Router
	) {}

	public intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return next.handle(request).pipe(
			map((httpEvent: HttpEvent<unknown>) => {
				if (httpEvent.type === HttpEventType.Response) {
					const contentType = httpEvent.headers.get('Content-Type');

					if (
						isNullOrUndefined(contentType)
						|| contentType.includes('application/json')
					) {
						httpEvent = httpEvent.clone({ body: httpEvent.body });
					}
				}

				return httpEvent;
			}),
			catchError(async errorException => {
				if (errorException instanceof HttpErrorResponse) {
					const { status, error, url } = errorException;

					await this.indexDbService.openOrCreateDatabaseStorage(
						exceptionLocalDbName,
						exceptionLocalDbStorageName
					);

					if (status >= HttpStatusCode.InternalServerError) {
						const exception = error as ExceptionModel;

						await this.indexDbService.addRecord({
							error:      exception.error,
							errorCode:  exception.errorCode,
							occureDate: moment().toDate(),
							status,
							url
						} as ExceptionModel);

						this.indexDbService.closeConnection();

						this.router
							.navigate([MaintenanceRoutes.Root])
							.catch(catchAppError);
					} else if (!request.url.endsWith(AuthRoutes.RefreshToken)) {
						let { errorCode } =
							error instanceof ArrayBuffer
								? JSON.parse(this.textDecoder.decode(error))
								: error;

						if (!errorCode) {
							errorCode = TwrxErrorCode.UnhandledError;
						}

						await this.indexDbService.addRecord({
							status,
							url,
							errorCode,
							occureDate: moment().toDate(),
							error:      TwrxErrorCode[errorCode]
						} as ExceptionModel);

						this.indexDbService.closeConnection();

						this.toastService.show({
							translationPath: `enums.TwrxErrorCode.${TwrxErrorCode[errorCode]}`,
							type:            NotificationType.Error,
							duration:        this.getNotificationDuration(errorCode)
						});

						throw errorCode;
					}
				}

				throw errorException;
			})
		);
	}

	private getNotificationDuration(errorCode: TwrxErrorCode): number {
		return match(errorCode)
			.with(
				TwrxErrorCode.WeakPasswordPattern,
				() => longMessageNotificationDuration
			)
			.otherwise(() => null);
	}
}
