import { Injectable } from '@angular/core';
import { AuthorizedAccountModel } from 'app/models/api/authorized-account-model';
import { CodeVerificationModel } from 'app/models/api/code-verification-model';
import { MfaChallengeModel } from 'app/models/api/mfa-challenge-model';
import { SignInModel } from 'app/models/api/sign-in-model';
import { TokenRequest } from 'app/models/api/token-request';
import { Observable } from 'rxjs';

import { AuthRoutes } from './api-routes';
import { HttpApiService } from './base/http-api.service';

@Injectable()
export class AuthApiService {
	rootRoute = AuthRoutes.Root;

	constructor(protected httpApiService: HttpApiService) {}

	public challenge(
		model: SignInModel,
		cancellationSubject: Observable<void>
	): Observable<MfaChallengeModel> {
		return this.httpApiService.post<MfaChallengeModel>(
			`${this.rootRoute}/${AuthRoutes.Challenge}`,
			model,
			cancellationSubject
		);
	}

	public resend(
		model: TokenRequest,
		cancellationSubject: Observable<void>
	): Observable<MfaChallengeModel> {
		return this.httpApiService.post<MfaChallengeModel>(
			`${this.rootRoute}/${AuthRoutes.Resend}`,
			model,
			cancellationSubject
		);
	}

	public verifyCode(
		model: CodeVerificationModel,
		cancellationSubject: Observable<void>
	): Observable<AuthorizedAccountModel> {
		return this.httpApiService.post<AuthorizedAccountModel>(
			`${this.rootRoute}/${AuthRoutes.VerifyCode}`,
			model,
			cancellationSubject
		);
	}

	public getToken(
		accessKey: string,
		cancellationSubject: Observable<void>
	): Observable<AuthorizedAccountModel> {
		return this.httpApiService.post<AuthorizedAccountModel>(
			`${this.rootRoute}/${AuthRoutes.Token}`,
			{ accessKey },
			cancellationSubject
		);
	}

	public refreshToken(): Observable<AuthorizedAccountModel> {
		return this.httpApiService.post<AuthorizedAccountModel>(
			`${this.rootRoute}/${AuthRoutes.RefreshToken}`
		);
	}
}
