import { NgClass, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChildren,
	ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgClassType } from 'app/common/interfaces/ng-class-type';
import { isNullOrUndefined } from 'app/common/utils/utils.object';

@Component({
	selector:        'app-dialog-menu',
	templateUrl:     './dialog-menu.component.html',
	encapsulation:   ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone:      true,
	imports:         [
		MatMenuModule,
		TranslocoModule,
		MatButtonModule,
		NgIf,
		MatIconModule,
		MatTooltipModule,
		NgClass
	]
})
export class DialogMenuComponent {
	@Input() icon: string;
	@Input() text: string;
	@Input() disabled: boolean;
	@Input() color: ThemePalette;
	@Input() ngButtonClass: NgClassType;

	@Input() title: string;
	@Input() message: string;
	@Input() translateMessage: boolean = true;

	@Input() tooltip: string;
	@Input() tooltipDisabled: boolean;
	@Input() tooltipShowDelay: number = 0;

	@Input() preOpen: () => boolean;

	@Output() confirmEvent: EventEmitter<MouseEvent> =
		new EventEmitter<MouseEvent>();

	@Output() menuOpened: EventEmitter<void> = new EventEmitter<void>();
	@Output() menuClosed: EventEmitter<void> = new EventEmitter<void>();

	@ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;

	public get getTitle(): string {
		return isNullOrUndefined(this.title) ? 'actions.question' : this.title;
	}

	public get isTooltipDisabled(): boolean {
		return isNullOrUndefined(this.tooltipDisabled)
			? this.disabled
			: this.tooltipDisabled;
	}

	public get isMenuOpened(): boolean {
		return this.triggers?.some((x: MatMenuTrigger) => x.menuOpen) ?? false;
	}

	public confirm($event: MouseEvent): void {
		this.confirmEvent.emit($event);
		this.close();
	}

	public close(): void {
		this.triggers?.forEach(trigger => {
			trigger.closeMenu();
		});
		this.menuClosed.emit();
	}

	public open($event: MouseEvent): void {
		$event.stopPropagation();

		if (!isNullOrUndefined(this.preOpen) && !this.preOpen()) {
			return;
		}

		this.triggers?.forEach(x => x.openMenu());
	}
}
