<!-- User Generated File button -->
<button
	#userFilesMenuTrigger="matMenuTrigger"
	mat-icon-button
	[matMenuTriggerFor]="userFiles"
	[matBadge]="1"
	[matTooltip]="'generatedFiles.title' | transloco"
	[matTooltipShowDelay]="listItemTooltipShowDelay"
	[ngClass]="{
		hidden: !fileExists
	}"
>
	<mat-icon
		fontSet="fas"
		fontIcon="fa-file-arrow-down"
		class="relative text-xl"
	></mat-icon>
</button>

<!-- User Generated File menu -->
<mat-menu
	xPosition="before"
	#userFiles="matMenu"
	class="min-w-96 overflow-hidden rounded-xl border shadow-2xl"
>
	<div
		*ngIf="fileExists"
		class="flex flex-col"
		(click)="$event.stopPropagation()"
	>
		<!-- Title -->
		<div class="text-secondary -mb-2 flex w-full flex-row items-center justify-between whitespace-nowrap px-3 py-1 text-lg leading-none">
			<span>{{ 'generatedFiles.title' | transloco }}</span>

			<!-- Close button -->
			<button
				mat-icon-button
				(click)="cancel()"
				[matTooltip]="'actions.close' | transloco"
			>
				<mat-icon
					fontSet="fas"
					fontIcon="fa-close"
					class="text-xl"
				></mat-icon>
			</button>
		</div>
		<mat-divider class="my-2"></mat-divider>

		<div class="flex flex-row items-center justify-between gap-3 px-3 pb-3 pt-1">
			<!-- File description -->
			<span class="whitespace-nowrap">
				{{ file.type | enumTranslate: 'enums.GeneratedFileTypeModel' : GeneratedFileTypeModel }}
			</span>

			<!-- File buttons/spinner -->
			<app-generated-file-button
				[generatedFileId]="file.id"
				[status]="file.status"
				(download)="download()"
				(delete)="delete()"
			></app-generated-file-button>
		</div>
	</div>
</mat-menu>
