import { createReducer, on } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import { match } from 'ts-pattern';

import {
	AuthenticationError,
	IAuth0State,
	initialAuth0State
} from './auth0.state';
import {
	appRedirect,
	authenticated,
	errorOccurred,
	idTokenUpdated,
	loaded,
	loading,
	setPermissions,
	signIn,
	signOut,
	signOutFailed,
	signOutSucceeded
} from './auth0.actions';

function getAuthenticationError(error: Error): AuthenticationError {
	return match(error)
		.with(
			{ message: AuthenticationError.UserBlocked },
			() => AuthenticationError.UserBlocked
		)
		.with(
			{ message: AuthenticationError.PasswordExpired },
			() => AuthenticationError.PasswordExpired
		)
		.with(
			{ message: AuthenticationError.UninvitedUser },
			() => AuthenticationError.UninvitedUser
		)
		.with(
			{ message: AuthenticationError.LoginRequired },
			() => AuthenticationError.LoginRequired
		)
		.otherwise(() => AuthenticationError.LoginRequired);
}

const reducer = createReducer(
	initialAuth0State,

	on(loading, state => ({
		...state,
		isLoading: true
	})),
	on(loaded, state => ({
		...state,
		isLoading: false
	})),

	on(authenticated, state => ({
		...state,
		isAuthenticated: true
	})),
	on(idTokenUpdated, state => ({
		...state
	})),
	on(setPermissions, (state, action) => ({
		...state,
		permissions: action.permissions
	})),
	on(appRedirect, state => ({
		...state
	})),

	on(signIn, state => ({
		...state
	})),

	on(signOut, state => ({
		...state
	})),
	on(signOutSucceeded, state => ({
		...state,
		isAuthenticated: false
	})),
	on(signOutFailed, state => ({
		...state
	})),

	on(errorOccurred, (state, action) => ({
		...state,
		error: getAuthenticationError(action.error)
	}))
);

export const auth0Reducer = (state: IAuth0State, action: Action): IAuth0State =>
	reducer(state, action);

export const auth0FeatureKey = 'auth0';
