import { FuseNavigationItem } from '@fuse/components/navigation';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountRoutes } from 'app/common/routes';
import { defaultNavigation } from 'app/core/navigation/navigation.data';
import { AccountModel } from 'app/models/api/account-model';
import { AuthorizedAccountModel } from 'app/models/api/authorized-account-model';
import { GeneratedFileModel } from 'app/models/api/generated-file-model';
import { NotificationsModel } from 'app/models/api/notifications-model';
import { PermissionModel } from 'app/models/api/permission-model';

import { accountFeatureKey } from './account.reducers';
import { AuthStatus, IAccountState } from './account.state';

export const selectAccount =
	createFeatureSelector<IAccountState>(accountFeatureKey);

export const selectAccountStatus = createSelector(
	selectAccount,
	(state: IAccountState): AuthStatus => state.status
);

export const selectGeneratedFile = createSelector(
	selectAccount,
	(state: IAccountState): GeneratedFileModel => state.generatedFile
);

export const selectNotifications = createSelector(
	selectAccount,
	(state: IAccountState): NotificationsModel => state.notifications
);

export const selectCurrentAccount = createSelector(
	selectAccount,
	(state: IAccountState): AuthorizedAccountModel => state.account
);

export const selectToken = createSelector(
	selectAccount,
	(state: IAccountState): string => state.token
);

export const selectRefreshToken = createSelector(
	selectAccount,
	(state: IAccountState): string => state.refreshToken
);

export const selectTokens = createSelector(
	selectToken,
	selectRefreshToken,
	(
		token: string,
		refreshToken: string
	): [token: string, refreshToken: string] => [token, refreshToken]
);

export const selectPermissions = createSelector(
	selectAccount,
	(state: IAccountState): PermissionModel[] => state.permissions
);

export const selectAuthenticatedRedirectUrlOrNoPermissions = createSelector(
	selectAccount,
	(state: IAccountState): string => {
		if (state.status !== AuthStatus.Authenticated) {
			return null;
		}

		if (state.redirectUrl) {
			return state.redirectUrl;
		}

		const firstPermittedPage = defaultNavigation.find(x =>
			x.meta.allowedPermissions?.some(mp =>
				state.permissions.some(sp => isPermitted(sp, mp))
			)
		)?.link;

		return firstPermittedPage ?? AccountRoutes.NoPermissions;
	}
);

export const selectCurrentUser = createSelector(
	selectAccount,
	(state: IAccountState): AccountModel => state.currentUser
);

export const selectIsAuthLoading = createSelector(
	selectAccount,
	(state: IAccountState): boolean => state.loadingRequestCount > 0
);

export const selectPasswordResetUrl = createSelector(
	selectAccount,
	(state: IAccountState): string => state.passwordResetUrl
);

export const selectHistoryEmail = createSelector(
	selectAccount,
	(state: IAccountState): string => state.historyEmail
);

export const selectNavigation = createSelector(
	selectAccount,
	(state: IAccountState): FuseNavigationItem[] =>
		filterNavigations(defaultNavigation, state.permissions)
);

const filterNavigations = (
	items: FuseNavigationItem[],
	permissions: PermissionModel[]
): FuseNavigationItem[] => {
	const rootNavigations = items.filter(item =>
		isNavigationPermitted(item, permissions)
	);
	const parentNavigations =
		rootNavigations.length === items.length
			? []
			: items
					.filter(
						item =>
							item.meta === undefined
							&& item.children !== undefined
					)
					.map(item => ({
						...item,
						children: filterNavigations(item.children, permissions)
					}))
					.filter(item => item.children.length > 0);

	return [...rootNavigations, ...parentNavigations];
};

const isNavigationPermitted = (
	item: FuseNavigationItem,
	permissions: PermissionModel[]
): boolean =>
	item.meta?.allowedPermissions.some((allowed: PermissionModel) =>
		permissions?.some(required => isPermitted(required, allowed))
	) ?? false;

const isPermitted = (p1: PermissionModel, p2: PermissionModel): boolean =>
	p1.scope === p2.scope && Boolean(p1.permission & p2.permission);

export const selectPreviousRoute = createSelector(
	selectAccount,
	(state: IAccountState) => state.previousRoute
);

export const selectIsAccountFormDisabled = createSelector(
	selectAccount,
	(state: IAccountState): boolean => state.disabled
);
