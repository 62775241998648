<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div class="flex w-full flex-auto flex-col">
	<!-- Content -->
	<div class="flex flex-auto flex-col">
		<router-outlet *ngIf="true"></router-outlet>
	</div>
</div>
