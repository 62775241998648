import { createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';

import { RouterStateUrl, StoreRootState } from './route.state';

export const getRouterState = (
	state: StoreRootState
): RouterReducerState<RouterStateUrl> => state.router;

export const getCurrentRouteState = createSelector(
	getRouterState,
	({ state }) => state ?? null
);

export const getCurrentId = createSelector(
	getCurrentRouteState,
	state => state?.params?.id
);

export const routeFeatureKey = 'route';
