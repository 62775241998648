import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerSearchModel } from 'app/models/api/customer-search-model';
import { GeneratedFileDownloadRequestModel } from 'app/models/api/generated-file-download-request-model';

import { CommonApiRoutes, GeneratedFileApiRoutes } from '../api-routes';
import { HttpApiService } from '../base/http-api.service';

@Injectable()
export class GeneratedFileApiService {
	protected rootRoute: string = GeneratedFileApiRoutes.Root;

	constructor(protected httpApiService: HttpApiService) {}

	public getUserFile(
		cancellationSubject: Observable<void>
	): Observable<CustomerSearchModel> {
		return this.httpApiService.get<CustomerSearchModel>(
			this.rootRoute,
			null,
			cancellationSubject
		);
	}

	public downloadFile(
		generatedFileId: number,
		cancellationSubject: Observable<void>
	): Observable<number> {
		return this.httpApiService.downloadFile(
			`${this.rootRoute}/${CommonApiRoutes.DownloadFile}`,
			{
				generatedFileId
			} as GeneratedFileDownloadRequestModel,
			cancellationSubject
		);
	}

	public deleteFile(cancellationSubject: Observable<void>): Observable<void> {
		return this.httpApiService.delete(
			this.rootRoute,
			null,
			cancellationSubject
		);
	}
}
