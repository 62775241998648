import { createAction, props } from '@ngrx/store';
import { PermissionModel } from 'app/models/api/permission-model';
import { IdToken } from '@auth0/auth0-angular';

export enum Auth0Actions {
	Loading = '[Auth0] Loading',
	Loaded = '[Auth0] Loaded',

	Authenticated = '[Auth0] Authenticated',
	IdTokenUpdated = '[Auth0] IdTokenUpdated',
	SetPermissions = '[Auth0] SetPermissions',
	AppRedirect = '[Auth0] AppRedirect',

	SignIn = '[Auth0] SignIn',

	SignOut = '[Auth0] SignOut',
	SignOutSucceeded = '[Auth0] SignOutSucceeded',
	SignOutFailed = '[Auth0] SignOutFailed',

	ErrorOccurred = '[Auth0] ErrorOccurred'
}

export const loading = createAction(Auth0Actions.Loading);

export const loaded = createAction(Auth0Actions.Loaded);

export const authenticated = createAction(Auth0Actions.Authenticated);

export const idTokenUpdated = createAction(
	Auth0Actions.IdTokenUpdated,
	props<IdToken>()
);

export const setPermissions = createAction(
	Auth0Actions.SetPermissions,
	props<{ permissions: PermissionModel[] }>()
);

export const appRedirect = createAction(Auth0Actions.AppRedirect);

export const signIn = createAction(
	Auth0Actions.SignIn,
	props<{ redirectUrl?: string; connection?: string }>()
);

export const signOut = createAction(Auth0Actions.SignOut);

export const signOutSucceeded = createAction(Auth0Actions.SignOutSucceeded);

export const signOutFailed = createAction(Auth0Actions.SignOutFailed);

export const errorOccurred = createAction(
	Auth0Actions.ErrorOccurred,
	props<{ error: Error }>()
);
