import { CanMatchFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { AuthProxyState } from 'app/store/auth-proxy/auth-proxy.state';
import { Location } from '@angular/common';
import { combineLatestWith, filter, of, startWith, switchMap } from 'rxjs';
import { authenticate } from 'app/store/auth-proxy/auth-proxy.actions';
import { isNullOrUndefined } from 'app/common/utils/utils.object';

export const Auth0LoadGuard: CanMatchFn = () => {
	const auth0Service = inject(AuthService);
	const store = inject(Store<AuthProxyState>);
	const location = inject(Location);

	return auth0Service.isLoading$.pipe(
		filter(isLoading => !isLoading),
		switchMap(() => auth0Service.isAuthenticated$),
		combineLatestWith(auth0Service.error$.pipe(startWith(null))),
		switchMap(([isAuthenticated, error]) => {
			if (!isNullOrUndefined(error)) {
				return of(false);
			}

			if (isAuthenticated) {
				return of(true);
			}

			store.dispatch(authenticate({ redirectUrl: location.path() }));

			return of(false);
		})
	);
};
