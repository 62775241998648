<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<app-empty-layout *ngIf="layout === 'empty'"></app-empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- Classic -->
<app-classic-layout *ngIf="layout === 'classic'"></app-classic-layout>
