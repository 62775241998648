<ng-container [ngSwitch]="status">
	<div
		class="flex flex-row gap-x-0.5"
		*ngSwitchCase="GeneratedFileStatusModel.Completed"
	>
		<!-- Download button -->
		<button
			mat-icon-button
			[matTooltip]="'actions.download' | transloco"
			(click)="download.emit()"
			color="primary"
		>
			<mat-icon
				fontSet="fas"
				fontIcon="fa-download"
				class="text-xl"
			></mat-icon>
		</button>

		<!-- Delete button -->
		<app-dialog-menu
			color="warn"
			icon="fa-trash"
			[message]="'generatedFiles.deleteConfirmationMessage' | transloco"
			[tooltip]="'actions.delete'"
			(confirmEvent)="delete.emit()"
		></app-dialog-menu>
	</div>

	<!-- Error -->
	<mat-icon
		*ngSwitchCase="GeneratedFileStatusModel.Failed"
		class="mr-0.5 text-base"
		color="warn"
		fontSet="fas"
		fontIcon="fa-triangle-exclamation"
		[matTooltip]="'enums.GeneratedFileStatusModel.Failed' | transloco"
	></mat-icon>

	<!-- Spinner -->
	<mat-progress-spinner
		*ngSwitchDefault
		class="mr-1"
		color="accent"
		mode="indeterminate"
		[diameter]="22"
		[matTooltip]="'enums.GeneratedFileStatusModel.Generating' | transloco"
	></mat-progress-spinner>
</ng-container>
