import { createReducer, on } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';

import {
	authenticationSucceeded,
	changeCurrentUserPasswordRequestSent,
	changeUserPasswordRequestSent,
	checkUsersPasswordResetRequired,
	codeVerified,
	confirmPasswordReset,
	currentUserInfoLoaded,
	currentUserInfoSaved,
	currentUserNotificationLoaded,
	forgotPasswordRequestSent,
	loadCurrentUserInfo,
	mfaVerificationStarted,
	passwordResetConfirmed,
	passwordResetVerifyCodeSent,
	refreshToken,
	refreshTokenFailed,
	refreshTokenSucceeded,
	resendSignIn,
	saveCurrentUserInfo,
	sendCurrentUserChangePasswordRequest,
	sendForgotPasswordRequest,
	sendPasswordResetVerifyCode,
	sendUserChangePasswordRequest,
	setPermissions,
	setRedirectUrl,
	setTokens,
	signIn,
	signInFailed,
	signOut,
	errorOccurred,
	updatePreviousRoute,
	usersPasswordResetRequiredChecked,
	verificationFailed,
	verificationStarted,
	verifyCode,
	currentUserLoaded,
	loadCurrentUser,
	currentUserGeneratedFileLoaded,
	downloadGeneratedFile,
	generatedFileDownloaded,
	generatedFileStatusUpdated,
	deleteGeneratedFile,
	generatedFileDeleted
} from './account.actions';
import {
	AuthStatus,
	IAccountState,
	initialAccountState
} from './account.state';

const reducer = createReducer(
	initialAccountState,

	on(signIn, (state, { data: { signInModel, rememberMe } }) => ({
		...state,
		signInData:          signInModel,
		historyEmail:        rememberMe ? signInModel.username : null,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(resendSignIn, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(mfaVerificationStarted, verificationStarted, (state, action) => ({
		...state,
		challenge:           action.data,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(signInFailed, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(verifyCode, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(codeVerified, (state, action) => ({
		...state,
		account:             action.data,
		loadingRequestCount: state.loadingRequestCount - 1
	})),
	on(setTokens, (state, { token, refreshToken }) => ({
		...state,
		token,
		refreshToken
	})),
	on(setPermissions, (state, action) => ({
		...state,
		permissions: action.permissions
	})),

	on(checkUsersPasswordResetRequired, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(usersPasswordResetRequiredChecked, (state, action) => ({
		...state,
		loadingRequestCount:   state.loadingRequestCount - 1,
		passwordResetRequired: action.data
	})),

	on(sendCurrentUserChangePasswordRequest, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(changeCurrentUserPasswordRequestSent, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(sendUserChangePasswordRequest, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(changeUserPasswordRequestSent, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(verificationFailed, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),
	on(authenticationSucceeded, state => ({
		...state,
		status:              AuthStatus.Authenticated,
		loadingRequestCount: state.loadingRequestCount + 1
	})),

	on(refreshToken, state => ({
		...state
	})),
	on(refreshTokenSucceeded, (state, action) => ({
		...state,
		status:  AuthStatus.Authenticated,
		account: action.data
	})),
	on(refreshTokenFailed, state => ({
		...state,
		status:       AuthStatus.SignedOut,
		account:      null,
		token:        null,
		refreshToken: null,
		currentUser:  null,
		permissions:  null
	})),

	on(signOut, state => ({
		...state,
		status:              AuthStatus.SignedOut,
		account:             null,
		token:               null,
		refreshToken:        null,
		currentUser:         null,
		loadingRequestCount: 0,
		permissions:         null
	})),

	on(sendForgotPasswordRequest, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(forgotPasswordRequestSent, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(sendPasswordResetVerifyCode, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(passwordResetVerifyCodeSent, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(confirmPasswordReset, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(passwordResetConfirmed, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(loadCurrentUser, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1,
		disabled:            true
	})),
	on(currentUserLoaded, (state, { data }) => ({
		...state,
		currentUser:         data,
		loadingRequestCount: state.loadingRequestCount - 1,
		disabled:            false
	})),

	on(loadCurrentUserInfo, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1,
		disabled:            true
	})),
	on(currentUserInfoLoaded, (state, action) => ({
		...state,
		currentUser: {
			...state.currentUser,
			id:    action.data.id,
			email: action.data.email
		},
		loadingRequestCount: state.loadingRequestCount - 1,
		disabled:            false
	})),
	on(saveCurrentUserInfo, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1,
		disabled:            true
	})),

	on(currentUserGeneratedFileLoaded, (state, { data }) => ({
		...state,
		generatedFile: data
	})),

	on(downloadGeneratedFile, deleteGeneratedFile, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount + 1
	})),
	on(generatedFileDownloaded, generatedFileDeleted, state => ({
		...state,
		generatedFile:       null,
		loadingRequestCount: state.loadingRequestCount - 1
	})),

	on(generatedFileStatusUpdated, (state, { data }) => ({
		...state,
		generatedFile: { ...state.generatedFile, status: data.status }
	})),

	on(currentUserNotificationLoaded, (state, { data }) => ({
		...state,
		notifications: data
	})),

	on(currentUserInfoSaved, (state, { data }) => ({
		...state,
		currentUser: {
			...state.currentUser,
			...data
		},
		loadingRequestCount: state.loadingRequestCount - 1,
		disabled:            false
	})),

	on(setRedirectUrl, (state, action) => ({
		...state,
		redirectUrl: action.redirectUrl
	})),

	on(updatePreviousRoute, (state, action) => ({
		...state,
		previousRoute: action.route
	})),

	on(errorOccurred, state => ({
		...state,
		loadingRequestCount: state.loadingRequestCount - 1,
		disabled:            false
	}))
);

export const accountReducer = (
	state: IAccountState,
	action: Action
): IAccountState => reducer(state, action);

export const accountFeatureKey = 'account';
