<!-- Notifications button -->
<button
	id="profileNotifications"
	[matMenuTriggerFor]="showNotifications ? userNotifications : null"
	[matTooltip]="'notifications.tooltip' | transloco"
	[matTooltipShowDelay]="listItemTooltipShowDelay"
	mat-icon-button
>
	<mat-icon
		[matBadge]="notificationCount"
		[matBadgeHidden]="!showNotifications"
		class="relative text-xl"
		fontIcon="fa-bell"
		fontSet="fas"
		matBadgeColor="primary"
	></mat-icon>
</button>

<!-- User menu -->
<mat-menu
	xPosition="before"
	#userNotifications="matMenu"
	class="min-w-60 overflow-hidden rounded-xl border shadow-2xl"
>
	<div class="flex flex-col justify-end">
		<span class="flex flex-col px-4 pt-4 leading-none">
			{{ 'notifications.title' | transloco: { count: notificationCount } }}
		</span>
		<mat-divider class="my-2"></mat-divider>
		<button
			mat-flat-button
			class="m-2 mt-0"
			color="primary"
			(click)="goToPayors()"
		>
			{{ 'notifications.submit' | transloco }}
		</button>
	</div>
</mat-menu>
