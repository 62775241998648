import { createAction, props } from '@ngrx/store';
import { CustomerBdcOptionsFilterModel } from 'app/models/api/customer-bdc-options-filter-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { CustomerBdcModel } from 'app/models/api/customer-bdc-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { CustomerBdcSearchModel } from 'app/models/api/customer-bdc-search-model';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { UpdateCustomerBdcModel } from 'app/models/api/update-customer-bdc-model';
import { CreateCustomerBdcModel } from 'app/models/api/create-customer-bdc-model';

export enum CustomerBdcActions {
	LoadCustomerBdcList = '[CustomerBdc] LoadCustomerBdcList',
	CustomerBdcListLoaded = '[CustomerBdc] CustomerBdcListLoaded',

	PreLoadCustomerBdc = '[CustomerBdc] PreLoadCustomerBdc',
	PreLoadCustomerBdcNotFound = '[CustomerBdc] PreLoadCustomerBdcNotFound',
	CustomerBdcPreLoaded = '[CustomerBdc] CustomerBdcPreLoaded',

	LoadCustomerBdc = '[CustomerBdc] LoadCustomerBdc',
	LoadCustomerBdcNotFound = '[CustomerBdc] LoadCustomerBdcNotFound',
	CustomerBdcLoaded = '[CustomerBdc] CustomerBdcLoaded',

	CreateCustomerBdc = '[CustomerBdc] CreateCustomerBdc',
	CustomerBdcCreated = '[CustomerBdc] CustomerBdcCreated',

	UpdateCustomerBdc = '[CustomerBdc] UpdateCustomerBdc',
	CustomerBdcUpdated = '[CustomerBdc] CustomerBdcUpdated',

	DeleteCustomerBdc = '[CustomerBdc] DeleteCustomerBdc',
	CustomerBdcDeleted = '[CustomerBdc] CustomerBdcDeleted',

	LoadBdcOptions = '[CustomerBdc] LoadBdcOptions',
	BdcOptionsLoaded = '[CustomerBdc] BdcOptionsLoaded',

	ErrorOccurred = '[CustomerBdc] ErrorOccurred'
}

export const loadCustomerBdcList = createAction(
	CustomerBdcActions.LoadCustomerBdcList,
	props<ActionRequestPayload<SearchRequest>>()
);

export const customerBdcListLoaded = createAction(
	CustomerBdcActions.CustomerBdcListLoaded,
	props<ActionResponsePayload<SearchResponse<CustomerBdcSearchModel>>>()
);

export const preLoadCustomerBdc = createAction(
	CustomerBdcActions.PreLoadCustomerBdc,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadCustomerBdcNotFound = createAction(
	CustomerBdcActions.PreLoadCustomerBdcNotFound
);

export const customerBdcPreLoaded = createAction(
	CustomerBdcActions.CustomerBdcPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<CustomerBdcModel>>>()
);

export const loadCustomerBdc = createAction(
	CustomerBdcActions.LoadCustomerBdc,
	props<ActionRequestPayload<number>>()
);

export const loadCustomerBdcNotFound = createAction(
	CustomerBdcActions.LoadCustomerBdcNotFound
);

export const customerBdcLoaded = createAction(
	CustomerBdcActions.CustomerBdcLoaded,
	props<ActionResponsePayload<CustomerBdcModel>>()
);

export const createCustomerBdc = createAction(
	CustomerBdcActions.CreateCustomerBdc,
	props<ActionRequestPayload<CreateCustomerBdcModel>>()
);

export const customerBdcCreated = createAction(
	CustomerBdcActions.CustomerBdcCreated
);

export const updateCustomerBdc = createAction(
	CustomerBdcActions.UpdateCustomerBdc,
	props<ActionRequestPayload<UpdateCustomerBdcModel>>()
);

export const customerBdcUpdated = createAction(
	CustomerBdcActions.CustomerBdcUpdated
);

export const deleteCustomerBdc = createAction(
	CustomerBdcActions.DeleteCustomerBdc,
	props<ActionRequestPayload<number>>()
);

export const customerBdcDeleted = createAction(
	CustomerBdcActions.CustomerBdcDeleted
);

export const loadBdcOptions = createAction(
	CustomerBdcActions.LoadBdcOptions,
	props<ActionRequestPayload<CustomerBdcOptionsFilterModel>>()
);

export const bdcOptionsLoaded = createAction(
	CustomerBdcActions.BdcOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const errorOccurred = createAction(CustomerBdcActions.ErrorOccurred);
