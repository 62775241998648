import { isArrayNullOrEmpty } from 'app/common/utils/utils.array';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { PermissionModel } from 'app/models/api/permission-model';
import { PermissionModelTypes } from 'app/models/api/permission-model-types';

import { PermissionScopes } from '../permission-scopes';

const isPermissionPermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes,
	type: PermissionModelTypes
): boolean =>
	isArrayNullOrEmpty(permissions)
	|| permissions.some(x => x.scope === scope && x.permission & type);

export const isPermitted = (
	permissions: PermissionModel[],
	permission: PermissionModel
): boolean =>
	isNullOrUndefined(permission)
	|| isPermissionPermitted(permissions, permission.scope, permission.permission);

export const isReadPermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes
): boolean =>
	isPermissionPermitted(permissions, scope, PermissionModelTypes.Read);

export const isAddPermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes
): boolean =>
	isPermissionPermitted(permissions, scope, PermissionModelTypes.Add);

export const isEditPermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes
): boolean =>
	isPermissionPermitted(permissions, scope, PermissionModelTypes.Edit);

export const isDeletePermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes
): boolean =>
	isPermissionPermitted(permissions, scope, PermissionModelTypes.Delete);

export const isExecutePermitted = (
	permissions: PermissionModel[],
	scope: PermissionScopes
): boolean =>
	isPermissionPermitted(permissions, scope, PermissionModelTypes.Execute);

export const isAnyPermitted = (
	permissions: PermissionModel[],
	allowed: PermissionModel[]
): boolean => allowed?.some(x => isPermitted(permissions, x));

export const getPermission = (
	scope: PermissionScopes,
	permission: PermissionModelTypes
): PermissionModel => ({
	scope,
	permission
});

export const getReadPermission = (scope: PermissionScopes): PermissionModel =>
	getPermission(scope, PermissionModelTypes.Read);

export const getAddPermission = (scope: PermissionScopes): PermissionModel =>
	getPermission(scope, PermissionModelTypes.Add);

export const getEditPermission = (scope: PermissionScopes): PermissionModel =>
	getPermission(scope, PermissionModelTypes.Edit);

export const getDeletePermission = (scope: PermissionScopes): PermissionModel =>
	getPermission(scope, PermissionModelTypes.Delete);

export const getExecutePermission = (
	scope: PermissionScopes
): PermissionModel => getPermission(scope, PermissionModelTypes.Execute);
