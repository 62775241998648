import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isNullOrUndefined } from 'app/common/utils/utils.object';

@Pipe({
	name:       'enumTranslate',
	standalone: true
})
export class EnumTranslatePipe<TEnum extends object> implements PipeTransform {
	constructor(private readonly translocoService: TranslocoService) {}

	transform(value: number, path: string, instance: TEnum): string {
		return !isNullOrUndefined(instance[value])
			? this.translocoService.translate(`${path}.${instance[value]}`)
			: null;
	}
}
