import { LineOfBusinessSubtypeOptionModel } from 'app/models/api/line-of-business-subtype-option-model';
import { ListOptionDataModel } from 'app/models/api/list-option-data-model';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { NdcReplacementMappingModel } from 'app/models/api/ndc-replacement-mapping-model';
import { PharmacyIdReviewStatus } from 'app/models/api/pharmacy-id-review-status';
import { RebateAggregatorNdcRateModel } from 'app/models/api/rebate-aggregator-ndc-rate-model';
import { RebatesLoadFileModelStatus } from 'app/models/api/rebates-load-file-model-status';
import { RebatesLoadFileSearchModel } from 'app/models/api/rebates-load-file-search-model';
import { SearchResponse } from 'app/models/api/search-response';
import { FacilityTableRecordModel } from 'app/models/dto/facility-table-record-model';
import { NdcReplacementMappingTableModel } from 'app/models/dto/ndc-replacement-mapping-table-model';
import { FormularyNdcStoreModel } from 'app/models/api/formulary-ndc-store-model';
import { RebatesLoadFileSearchStoreModel } from 'app/models/dto/rebates-load-file-search-store-model';
import { environment } from 'environments/environment';
import moment from 'moment/moment';
import { first, Observable, switchMap, timer } from 'rxjs';
import { NotReviewedPharmacyIdItemModel } from 'app/models/api/not-reviewed-pharmacy-id-item-model';

import {
	debounceTimeInMs,
	minValidNcpdpLength,
	ncpdpFiller,
	ncpdpLength
} from '../app-constants';

import { isNullOrUndefined } from './utils.object';
import { isEmptyOrWhiteSpace, toHierarchyString } from './utils.string';

export const isAmountIntersection = (
	from1: number,
	to1: number,
	from2: number,
	to2: number
): boolean => {
	const range1To = to1 ?? Number.MAX_SAFE_INTEGER;
	const range2To = to2 ?? Number.MAX_SAFE_INTEGER;

	return !(range1To < from2 || from1 > range2To);
};

export const combineWithAggregatorAndFormulariesOptionsById = (
	records: FormularyNdcStoreModel[] | RebateAggregatorNdcRateModel[],
	rebateAggregators: ListOptionModel<number>[],
	rebateFormularies: ListOptionModel<number>[]
): FormularyNdcStoreModel[] | RebateAggregatorNdcRateModel[] =>
	records.map(record => ({
		...record,
		aggregator: rebateAggregators.find(
			({ value }) => value === record.aggregatorId
		).name,
		formulary: rebateFormularies.find(
			({ value }) => value === record.formularyId
		).name
	}));

export const updateFileStatus = (
	files: RebatesLoadFileSearchStoreModel[],
	fileId: number,
	status: RebatesLoadFileModelStatus
): RebatesLoadFileSearchStoreModel[] => {
	files?.forEach(x => {
		if (x.id === fileId) {
			x.status = status;

			return files;
		}
	});

	return files;
};

export const updatePharmacyIdReviewStatus = (
	reviewModels: NotReviewedPharmacyIdItemModel[],
	reviewId: number,
	status: PharmacyIdReviewStatus
): NotReviewedPharmacyIdItemModel[] => {
	reviewModels?.forEach(x => {
		if (x.id === reviewId) {
			x.status = status;

			return reviewModels;
		}
	});

	return reviewModels;
};

export const combineRebateLoadSearchModelWithAccountId = (
	result: SearchResponse<RebatesLoadFileSearchModel>,
	accountId: number,
	isPermitted: boolean
): SearchResponse<RebatesLoadFileSearchStoreModel> => ({
	...result,
	records: result.records.map(x => ({
		...x,
		deleteEnabled:
			!x.isSubmitted && (isPermitted || accountId === x.createdById)
	}))
});

export const getStateOptions = (
	states: string[]
): ListOptionModel<string>[] => {
	const sorted: string[] = states.sort((a, b) => a.localeCompare(b));

	return sorted.map(x => ({ value: x, name: x }));
};

export const combineLobWithLobSubtype = (
	lobOptions: ListOptionModel<number>[],
	lobSubtypeOptions: LineOfBusinessSubtypeOptionModel[]
): ListOptionModel<number>[] =>
	lobOptions
		.map(lob =>
			lobSubtypeOptions
				.filter(x => x.lobId === lob.value)
				.map(
					lobSubtype =>
						({
							value: lobSubtype.value,
							name:  `${lob.name} / ${lobSubtype.name}`
						}) as ListOptionModel<number>
				)
		)
		.flatMap(x => x);

export const checkFacilityTypeWithCustomerTypes = (
	facilities: FacilityTableRecordModel[],
	customerLobSubtypes: number[]
): FacilityTableRecordModel[] =>
	facilities.map(x => ({
		...x,
		hasWarning: isNullOrUndefined(
			customerLobSubtypes.find(t => t === x.typeId)
		)
	}));

export const debouncer = <T>(callback: () => Observable<T>): Observable<T> =>
	timer(debounceTimeInMs).pipe(
		switchMap(() => callback()),
		first()
	);

export const getLocationHierarchyString = (
	name: string,
	state: string
): string => {
	if (isNullOrUndefined(name)) {
		return null;
	}

	if (isNullOrUndefined(state)) {
		return toHierarchyString(name);
	}

	return toHierarchyString(`${name}${state}`);
};

export const combineCompleteUrl = <T>(url: string, data: T = null): string => {
	let requestData = '';

	if (data) {
		if (typeof data === 'object') {
			requestData = `?request=${encodeURIComponent(
				JSON.stringify(data)
			)}`;
		} else {
			requestData = `/${data as unknown as string}`;
		}
	}

	return `${environment.apiUrl}/${url}${requestData}`;
};

export const formatNcpdpString = (value: string): string => {
	if (isEmptyOrWhiteSpace(value)) {
		return null;
	}

	return value.length === minValidNcpdpLength
		? value.padStart(ncpdpLength, ncpdpFiller)
		: value;
};

export const disableNdcReplacementMappingRecords = (
	records: NdcReplacementMappingModel[],
	periods: ListOptionDataModel<number, string>[]
): NdcReplacementMappingTableModel[] => {
	return records.map(record => {
		const endPeriod = periods?.find(
			({ value }) => value === record.endSubmissionPeriodId
		)?.data;

		return {
			...record,
			disabled:
				!isNullOrUndefined(endPeriod)
				&& moment().startOf('date').isAfter(endPeriod)
		};
	});
};
