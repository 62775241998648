import { NgClass } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector:        'app-vertical-navigation-divider-item',
	templateUrl:     './divider.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone:      true,
	imports:         [NgClass]
})
export class VerticalNavigationDividerItemComponent
	implements OnInit, OnDestroy
{
	@Input() item: FuseNavigationItem;
	@Input() name: string;

	private verticalNavigationComponent: VerticalNavigationComponent;
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private fuseNavigationService: FuseNavigationService
	) {}

	ngOnInit(): void {
		// Get the parent navigation component
		this.verticalNavigationComponent =
			this.fuseNavigationService.getComponent(this.name);

		// Subscribe to onRefreshed on the navigation component
		this.verticalNavigationComponent.onRefreshed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}
}
