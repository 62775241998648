import { createAction, props } from '@ngrx/store';

import { ActionRequestPayload } from '../action-request-payload';

export enum AuthProxyActions {
	AppInitialized = '[AuthProxy] AppInitialized',

	Authenticate = '[AuthProxy] Authenticate',
	SignOut = '[AuthProxy] SignOut',

	ResetCurrentUserPassword = '[AuthProxy] ResetCurrentUserPassword'
}

export const appInitialized = createAction(AuthProxyActions.AppInitialized);

export const authenticate = createAction(
	AuthProxyActions.Authenticate,
	props<{ redirectUrl: string }>()
);

export const signOut = createAction(AuthProxyActions.SignOut);

export const resetCurrentUserPassword = createAction(
	AuthProxyActions.ResetCurrentUserPassword,
	props<ActionRequestPayload<number>>()
);
