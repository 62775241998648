import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IdToken } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchAppError } from 'app/common/utils/utils.error';
import { PermissionModel } from 'app/models/api/permission-model';
import { selectAuthenticatedRedirectUrlOrNoPermissions } from 'app/store/auth0/auth0.selectors';
import { environment } from 'environments/environment';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { Auth0Actions, setPermissions } from './auth0.actions';
import { IAuth0State } from './auth0.state';

const permissionsClaim = 'permissions';
const policyDelimiter = ':';

function extractPermissions(idToken: IdToken): PermissionModel[] {
	const { auth0 } = environment;
	const policies: string[] =
		idToken?.[
			`${auth0.authorizationParams.audience}/${permissionsClaim}`
		] ?? [];

	return policies
		.map(policy => policy.split(policyDelimiter))
		.map(
			([scope, permission]) =>
				({
					scope,
					permission: parseInt(permission, 10)
				}) as PermissionModel
		);
}

@Injectable()
export class Auth0Effects {
	idTokenUpdated$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(Auth0Actions.IdTokenUpdated),
				tap((idToken: IdToken) => {
					const permissions = extractPermissions(idToken);

					this.store.dispatch(setPermissions({ permissions }));
				})
			),
		{ dispatch: false }
	);

	appRedirect$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(Auth0Actions.AppRedirect),
				switchMap(() =>
					this.store.select(
						selectAuthenticatedRedirectUrlOrNoPermissions
					)
				),
				tap(redirectUrl => {
					this.router.navigate([redirectUrl]).catch(catchAppError);
				})
			),
		{ dispatch: false }
	);

	signIn$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(Auth0Actions.SignIn),
				switchMap(({ redirectUrl, connection }) =>
					this.auth0Service.loginWithRedirect({
						appState:            { target: redirectUrl },
						authorizationParams: { connection }
					})
				)
			),
		{ dispatch: false }
	);

	signOut$ = createEffect(() =>
		this.actions.pipe(
			ofType(Auth0Actions.SignOut),
			switchMap(() =>
				this.auth0Service
					.logout({
						logoutParams: { returnTo: window.location.origin }
					})
					.pipe(
						map(() => ({ type: Auth0Actions.SignOutSucceeded })),
						catchError(() =>
							of({ type: Auth0Actions.SignOutFailed })
						)
					)
			)
		)
	);

	errorOccurred$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(Auth0Actions.ErrorOccurred),
				switchMap(() => this.auth0Service.isAuthenticated$),
				tap(isAuthenticated => {
					if (isAuthenticated) {
						window.location.reload();
					}
				})
			),
		{ dispatch: false }
	);

	constructor(
		private readonly store: Store<IAuth0State>,
		private readonly actions: Actions,
		private readonly auth0Service: AuthService,
		private readonly router: Router
	) {}
}
