import { createAction, props } from '@ngrx/store';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { NdcReplacementMappingModel } from 'app/models/api/ndc-replacement-mapping-model';
import { NdcReplacementMappingTableModel } from 'app/models/dto/ndc-replacement-mapping-table-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { CreateNdcReplacementMappingModel } from 'app/models/api/create-ndc-replacement-mapping-model';
import { UpdateNdcReplacementMappingModel } from 'app/models/api/update-ndc-replacement-mapping-model';

import { ActionResponsePayload } from '../action-response-payload';
import { ActionRequestPayload } from '../action-request-payload';

export enum NdcReplacementMappingsActions {
	LoadNdcReplacementMappings = '[NDC Replacement Mappings] LoadNdcReplacementMappings',
	NdcReplacementMappingsLoaded = '[NDC Replacement Mappings] NdcReplacementMappingsLoaded',

	PreloadNdcReplacementMapping = '[NDC Replacement Mappings] PreloadNdcReplacementMapping',
	NdcReplacementMappingPreloaded = '[NDC Replacement Mappings] NdcReplacementMappingPreloaded',
	PreloadNdcReplacementMappingNotFound = '[NDC Replacement Mappings] PreloadNdcReplacementMappingNotFound',

	LoadNdcReplacementMappingsById = '[NDC Replacement Mappings] LoadNdcReplacementMappingsById',
	LoadNdcReplacementMappingsByIdLoaded = '[NDC Replacement Mappings] LoadNdcReplacementMappingsByIdLoaded',
	LoadNdcReplacementMappingsByIdNotFound = '[NDC Replacement Mappings] LoadNdcReplacementMappingsByIdNotFound',

	CreateNdcReplacementMapping = '[NDC Replacement Mappings] CreateNdcReplacementMappings',
	NdcReplacementMappingCreated = '[NDC Replacement Mappings] NdcReplacementMappingsCreated',

	UpdateNdcReplacementMapping = '[NDC Replacement Mappings] UpdateNdcReplacementMappings',
	NdcReplacementMappingUpdated = '[NDC Replacement Mappings] NdcReplacementMappingsUpdated',

	ErrorOccurred = '[NDC Replacement Mappings] ErrorOccurred'
}

export const loadNdcReplacementMappings = createAction(
	NdcReplacementMappingsActions.LoadNdcReplacementMappings,
	props<ActionRequestPayload<SearchRequest>>()
);

export const ndcReplacementMappingsLoaded = createAction(
	NdcReplacementMappingsActions.NdcReplacementMappingsLoaded,
	props<
		ActionResponsePayload<SearchResponse<NdcReplacementMappingTableModel>>
	>()
);

export const preloadNdcReplacementMapping = createAction(
	NdcReplacementMappingsActions.PreloadNdcReplacementMapping,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const ndcReplacementMappingPreLoaded = createAction(
	NdcReplacementMappingsActions.NdcReplacementMappingPreloaded,
	props<ActionResponsePayload<IPreLoadModel<NdcReplacementMappingModel>>>()
);

export const preloadNdcReplacementMappingNotFound = createAction(
	NdcReplacementMappingsActions.PreloadNdcReplacementMappingNotFound
);

export const loadNdcReplacementMappingsById = createAction(
	NdcReplacementMappingsActions.LoadNdcReplacementMappingsById,
	props<ActionRequestPayload<number>>()
);

export const loadNdcReplacementMappingsByIdLoaded = createAction(
	NdcReplacementMappingsActions.LoadNdcReplacementMappingsByIdLoaded,
	props<ActionResponsePayload<NdcReplacementMappingModel>>()
);

export const loadNdcReplacementMappingsByIdNotFound = createAction(
	NdcReplacementMappingsActions.LoadNdcReplacementMappingsByIdNotFound
);

export const createNdcReplacementMapping = createAction(
	NdcReplacementMappingsActions.CreateNdcReplacementMapping,
	props<ActionRequestPayload<CreateNdcReplacementMappingModel>>()
);

export const ndcReplacementMappingCreated = createAction(
	NdcReplacementMappingsActions.NdcReplacementMappingCreated
);

export const updateNdcReplacementMapping = createAction(
	NdcReplacementMappingsActions.UpdateNdcReplacementMapping,
	props<ActionRequestPayload<UpdateNdcReplacementMappingModel>>()
);

export const ndcReplacementMappingUpdated = createAction(
	NdcReplacementMappingsActions.NdcReplacementMappingUpdated
);

export const errorOccurred = createAction(
	NdcReplacementMappingsActions.ErrorOccurred
);
