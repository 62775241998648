import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { accountFeatureKey } from './account.reducers';
import { IAccountState, initialAccountState } from './account.state';

export const accountLocalStorageSyncReducer = (
	reducer: ActionReducer<IAccountState>
): ActionReducer<IAccountState> =>
	localStorageSync({
		keys: [
			'status',
			'account',
			'historyEmail',
			'token',
			'refreshToken',
			'permissions'
		],
		rehydrate:    true,
		mergeReducer: (
			state: IAccountState,
			rehydratedState: Partial<IAccountState>,
			action: { features: string[] }
		) => {
			if (
				(!state || !Object.keys(state).length)
				&& action?.features?.some(x => x === accountFeatureKey)
			) {
				return {
					...initialAccountState,
					...rehydratedState
				};
			}

			return state;
		}
	})(reducer);

export const accountMetaReducers: Array<MetaReducer<IAccountState>> = [
	accountLocalStorageSyncReducer
];
