import { isCollectionEmpty } from './utils.array';
import { isEmptyOrWhiteSpace, toCamelCase } from './utils.string';

export const isObject = (obj: unknown): boolean =>
	!isNullOrUndefined(obj) && typeof obj === 'object';

export const isNull = (value: unknown): value is null => value === null;

export const isUndefined = (value: unknown): value is undefined =>
	value === undefined;

export const isNullOrUndefined = <T>(value: T): boolean =>
	isNull(value) || isUndefined(value);

export const isObjectNullishOrEmptyString = <T>(value: T | string): boolean =>
	typeof value === 'string'
		? isEmptyOrWhiteSpace(value)
		: isNullOrUndefined(value);

export const isObjectEmpty = <T>(object: T): boolean =>
	isCollectionEmpty(Object.keys(object));

export const copyObject = <T>(object: T): T =>
	JSON.parse(JSON.stringify(object));

export const keysToCamelCase = <T extends Record<string, unknown>>(
	object: T
): T => {
	if (isNullOrUndefined(object)) {
		return object;
	}

	return Object.entries(object).reduce((acc, [key, value]) => {
		const camelCaseKey = toCamelCase(key);

		return { ...acc, [camelCaseKey]: value };
	}, {} as T);
};

export const deepCompare = <T>(obj1: T, obj2: T): boolean => {
	if (!isObject(obj1) || !isObject(obj2)) {
		return obj1 === obj2;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
			return false;
		}

		if (!deepCompare(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};

export const merge = <T>(target: T, source: T): T => {
	if (!isObject(target) || !isObject(source)) {
		return source;
	}

	Object.keys(source).forEach((key: string) => {
		const targetValue = target[key];
		const sourceValue = source[key];

		if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
			target[key] = targetValue.concat(sourceValue);

			return;
		}

		if (isObject(targetValue) && isObject(sourceValue)) {
			target[key] = merge(copyObject(targetValue), sourceValue);

			return;
		}

		target[key] = sourceValue;
	});

	return target;
};

export const pick = <T, K extends keyof T>(
	obj: T,
	...keys: K[]
): Pick<T, K> => {
	const entries = keys
		.filter(key => !isNullOrUndefined(obj[key]))
		.map(key => [key, obj[key]]);

	return Object.fromEntries(entries);
};
