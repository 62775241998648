import {
	HTTP_INTERCEPTORS,
	HttpClientModule,
	provideHttpClient
} from '@angular/common/http';
import {
	APP_INITIALIZER,
	ApplicationConfig,
	ErrorHandler,
	importProvidersFrom,
	inject,
	ModuleWithProviders,
	Provider
} from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
	provideRouter,
	Router,
	withInMemoryScrolling,
	withPreloading
} from '@angular/router';
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular';
import {
	provideTransloco,
	Translation,
	TranslocoService
} from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appRoutes } from 'app/app.routes';
import { IdentityProviders } from 'app/common/identity-providers';
import { ErrorHandlerInterceptor } from 'app/core/interceptors/error-handler.interceptor';
import { TokenInterceptor } from 'app/core/interceptors/token.interceptor';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { HttpApiService } from 'app/services/api/base/http-api.service';
import { AppInitService } from 'app/services/app-init.service';
import { EventNotifierService } from 'app/services/event-notifier.service';
import { ToastService } from 'app/services/toast.service';
import {
	AccountEffects,
	accountEffectsProviders
} from 'app/store/account/account.effects';
import { accountMetaReducers } from 'app/store/account/account.meta-reducers';
import {
	accountFeatureKey,
	accountReducer
} from 'app/store/account/account.reducers';
import { AuthProxyEffects } from 'app/store/auth-proxy/auth-proxy.effects';
import { Auth0Effects } from 'app/store/auth0/auth0.effects';
import { auth0FeatureKey, auth0Reducer } from 'app/store/auth0/auth0.reducers';
import { RouteStoreSerializer } from 'app/store/route/route-store-serializer';
import { routeFeatureKey } from 'app/store/route/route.selectors';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'environments/environment';
import { IConfig, provideNgxMask } from 'ngx-mask';
import { firstValueFrom, Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { applicationInsightsProviders } from 'app/services/application-insights.service';
import { SignalRService } from 'app/services/notifications/base/signalr-service';
import { AuthenticatedPreloadingStrategy } from 'app/core/routing/authenticated-preloading-strategy';

import { provideFuse } from './../@fuse';

const maskConfig: Partial<IConfig> = {
	validation: true
};

const auth0Imports: ModuleWithProviders<NonNullable<unknown>>[] = [
	EffectsModule.forFeature([Auth0Effects]),
	StoreModule.forFeature(auth0FeatureKey, auth0Reducer)
];

const authInterceptors: Provider[] =
	environment.identityProvider === IdentityProviders.Auth0
		? [
				{
					provide:  HTTP_INTERCEPTORS,
					useClass: AuthHttpInterceptor,
					multi:    true
				}
			]
		: [
				{
					provide:  HTTP_INTERCEPTORS,
					useClass: TokenInterceptor,
					multi:    true
				}
			];

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		provideHttpClient(),
		provideRouter(
			appRoutes,
			withPreloading(AuthenticatedPreloadingStrategy),
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
		),
		environment.identityProvider === IdentityProviders.Auth0
			? provideAuth0({ ...environment.auth0 })
			: [],

		// Transloco Config
		provideTransloco({
			config: {
				availableLangs: [
					{
						id:    'en',
						label: 'English'
					}
				],
				defaultLang:          'en',
				fallbackLang:         'en',
				failedRetries:        4,
				prodMode:             environment.production,
				reRenderOnLangChange: true
			},
			loader: TranslocoHttpLoader
		}),
		{
			// Preload the default language before the app starts to prevent empty/jumping content
			provide:    APP_INITIALIZER,
			useFactory: (): (() => Translation) => {
				const translocoService = inject(TranslocoService);
				const defaultLang = translocoService.getDefaultLang();

				translocoService.setActiveLang(defaultLang);

				return () => firstValueFrom(translocoService.load(defaultLang));
			},
			multi: true
		},

		// NgxMask Config
		provideNgxMask(maskConfig),

		{
			provide: APP_INITIALIZER,
			useFactory:
				(startupClass: AppInitService) => (): Observable<unknown> =>
					startupClass.initApplication(),
			deps:  [AppInitService],
			multi: true
		},
		...authInterceptors,
		{
			provide:  HTTP_INTERCEPTORS,
			useClass: ErrorHandlerInterceptor,
			multi:    true
		},

		AppInitService,
		ToastService,
		EventNotifierService,
		BnNgIdleService,
		SignalRService,
		AuthenticatedPreloadingStrategy,

		// API Services:
		HttpApiService,
		...accountEffectsProviders,

		importProvidersFrom(
			HttpClientModule,

			// Date Adapter:
			MatMomentDateModule,

			// NgRx State:
			StoreModule.forRoot(
				{},
				{
					runtimeChecks: {
						strictStateImmutability:  false,
						strictActionImmutability: false
					}
				}
			),
			EffectsModule.forRoot([]),
			EffectsModule.forFeature([AccountEffects, AuthProxyEffects]),
			StoreModule.forFeature(accountFeatureKey, accountReducer, {
				metaReducers: accountMetaReducers
			}),
			StoreRouterConnectingModule.forRoot({
				stateKey:   routeFeatureKey,
				serializer: RouteStoreSerializer
			}),

			// Environment:
			environment.production ? [] : StoreDevtoolsModule.instrument(),
			environment.identityProvider === IdentityProviders.Auth0
				? auth0Imports
				: []
		),

		// Fuse
		provideFuse({
			fuse: {
				layout:  'classic',
				scheme:  'light',
				screens: {
					sm: '600px',
					md: '960px',
					lg: '1280px',
					xl: '1440px'
				},
				theme:  'theme-default',
				themes: [
					{
						id:   'theme-default',
						name: 'Default'
					}
				]
			}
		}),

		// Sentry Config
		{
			provide:  ErrorHandler,
			useValue: Sentry.createErrorHandler()
		},
		{
			provide: Sentry.TraceService,
			deps:    [Router]
		},
		{
			provide:    APP_INITIALIZER,
			useFactory: () => () => {},
			deps:       [Sentry.TraceService],
			multi:      true
		},
		applicationInsightsProviders()
	]
};
