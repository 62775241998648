import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { VerticalNavigationBasicItemComponent } from 'app/layout/layouts/classic/vertical/components/basic/basic.component';
import { VerticalNavigationCollapsableItemComponent } from 'app/layout/layouts/classic/vertical/components/collapsable/collapsable.component';
import { VerticalNavigationDividerItemComponent } from 'app/layout/layouts/classic/vertical/components/divider/divider.component';
import { VerticalNavigationGroupItemComponent } from 'app/layout/layouts/classic/vertical/components/group/group.component';
import { VerticalNavigationSpacerItemComponent } from 'app/layout/layouts/classic/vertical/components/spacer/spacer.component';
import { VerticalNavigationComponent } from 'app/layout/layouts/classic/vertical/vertical.component';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
	selector:        'app-vertical-navigation-aside-item',
	templateUrl:     './aside.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone:      true,
	imports:         [
		NgClass,
		MatTooltipModule,
		NgIf,
		MatIconModule,
		NgFor,
		VerticalNavigationBasicItemComponent,
		VerticalNavigationCollapsableItemComponent,
		VerticalNavigationDividerItemComponent,
		VerticalNavigationGroupItemComponent,
		VerticalNavigationSpacerItemComponent
	]
})
export class VerticalNavigationAsideItemComponent
	implements OnChanges, OnInit, OnDestroy
{
	/* eslint-disable @typescript-eslint/naming-convention */
	static ngAcceptInputType_autoCollapse: BooleanInput;
	static ngAcceptInputType_skipChildren: BooleanInput;
	/* eslint-enable @typescript-eslint/naming-convention */

	@Input() activeItemId: string;
	@Input() autoCollapse: boolean;
	@Input() item: FuseNavigationItem;
	@Input() name: string;
	@Input() skipChildren: boolean;

	active: boolean = false;
	private verticalNavigationComponent: VerticalNavigationComponent;
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		private fuseNavigationService: FuseNavigationService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		// Active item id
		if ('activeItemId' in changes) {
			// Mark if active
			this.markIfActive(this.router.url);
		}
	}

	ngOnInit(): void {
		// Mark if active
		this.markIfActive(this.router.url);

		// Attach a listener to the NavigationEnd event
		this.router.events
			.pipe(
				filter(
					(event): event is NavigationEnd =>
						event instanceof NavigationEnd
				),
				takeUntil(this.unsubscribeAll)
			)
			.subscribe((event: NavigationEnd) => {
				// Mark if active
				this.markIfActive(event.urlAfterRedirects);
			});

		// Get the parent navigation component
		this.verticalNavigationComponent =
			this.fuseNavigationService.getComponent(this.name);

		// Subscribe to onRefreshed on the navigation component
		this.verticalNavigationComponent.onRefreshed
			.pipe(takeUntil(this.unsubscribeAll))
			.subscribe(() => {
				// Mark for check
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}

	trackByFn(index: number, item: FuseNavigationItem): unknown {
		return item.id || item.children || index;
	}

	/**
	 * Check if the given item has the given url
	 * in one of its children
	 *
	 * @param item
	 * @param currentUrl
	 * @private
	 */
	private hasActiveChild(
		item: FuseNavigationItem,
		currentUrl: string
	): boolean {
		const children = item.children;

		if (!children) {
			return false;
		}

		for (const child of children) {
			if (child.children) {
				if (this.hasActiveChild(child, currentUrl)) {
					return true;
				}
			}

			// Skip items other than 'basic'
			if (child.type !== 'basic') {
				continue;
			}

			// Check if the child has a link and is active
			if (
				child.link
				&& this.router.isActive(child.link, child.exactMatch || false)
			) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Decide and mark if the item is active
	 *
	 * @private
	 */
	private markIfActive(currentUrl: string): void {
		// Check if the activeItemId is equals to this item id
		this.active = this.activeItemId === this.item.id;

		// If the aside has a children that is active,
		// always mark it as active
		if (this.hasActiveChild(this.item, currentUrl)) {
			this.active = true;
		}

		// Mark for check
		this.changeDetectorRef.markForCheck();
	}
}
