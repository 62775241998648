import { createAction, props } from '@ngrx/store';
import { UnbreakableQuantityModel } from 'app/models/api/unbreakable-quantity-model';
import { CreateUnbreakableQuantityModel } from 'app/models/api/create-unbreakable-quantity-model';
import { SearchResponse } from 'app/models/api/search-response';
import { UpdateUnbreakableQuantityModel } from 'app/models/api/update-unbreakable-quantity-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { UnbreakableQuantityMedispanDataModel } from 'app/models/api/unbreakable-quantity-medispan-data-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { BaseSearchColumnFilterModel } from 'app/models/api/base-search-column-filter-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum UnbreakableQuantityActions {
	LoadUnbreakableQuantity = '[UnbreakableQuantity] LoadUnbreakableQuantity',
	UnbreakableQuantityLoaded = '[UnbreakableQuantity] UnbreakableQuantityLoaded',

	PreLoadUnbreakableQuantity = '[UnbreakableQuantity] PreLoadUnbreakableQuantity',
	UnbreakableQuantityPreLoaded = '[UnbreakableQuantity] UnbreakableQuantityPreLoaded',
	PreLoadUnbreakableQuantityNotFound = '[UnbreakableQuantity] PreLoadUnbreakableQuantityNotFound',

	LoadUnbreakableQuantityById = '[UnbreakableQuantity] LoadUnbreakableQuantityById',
	LoadUnbreakableQuantityNotFound = '[UnbreakableQuantity] LoadUnbreakableQuantityNotFound',
	UnbreakableQuantityByIdLoaded = '[UnbreakableQuantity] UnbreakableQuantityByIdLoaded',

	LoadUnbreakableQuantityDetailsByNdc = '[UnbreakableQuantity] LoadUnbreakableQuantityDetailsByNdc',
	UnbreakableQuantityDetailsByNdcLoaded = '[UnbreakableQuantity] UnbreakableQuantityDetailsByNdcLoaded',

	CreateUnbreakableQuantity = '[UnbreakableQuantity] CreateUnbreakableQuantity',
	UnbreakableQuantityCreated = '[UnbreakableQuantity] UnbreakableQuantityCreated',

	UpdateUnbreakableQuantity = '[UnbreakableQuantity] UpdateUnbreakableQuantity',
	UnbreakableQuantityUpdated = '[UnbreakableQuantity] UnbreakableQuantityUpdated',

	DeleteUnbreakableQuantity = '[UnbreakableQuantity] DeleteUnbreakableQuantity',
	UnbreakableQuantityDeleted = '[UnbreakableQuantity] UnbreakableQuantityDeleted',

	ErrorOccurred = '[UnbreakableQuantity] ErrorOccurred'
}

export const loadUnbreakableQuantityList = createAction(
	UnbreakableQuantityActions.LoadUnbreakableQuantity,
	props<
		ActionRequestPayload<FilterSearchRequest<BaseSearchColumnFilterModel>>
	>()
);

export const unbreakableQuantityLoaded = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityLoaded,
	props<ActionResponsePayload<SearchResponse<UnbreakableQuantityModel>>>()
);

export const preLoadUnbreakableQuantityById = createAction(
	UnbreakableQuantityActions.PreLoadUnbreakableQuantity,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadUnbreakableQuantityNotFound = createAction(
	UnbreakableQuantityActions.PreLoadUnbreakableQuantityNotFound
);

export const unbreakableQuantityByIdPreLoaded = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<UnbreakableQuantityModel>>>()
);

export const loadUnbreakableQuantityById = createAction(
	UnbreakableQuantityActions.LoadUnbreakableQuantityById,
	props<ActionRequestPayload<number>>()
);

export const loadUnbreakableQuantityNotFound = createAction(
	UnbreakableQuantityActions.LoadUnbreakableQuantityNotFound
);

export const unbreakableQuantityByIdLoaded = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityByIdLoaded,
	props<ActionResponsePayload<UnbreakableQuantityModel>>()
);

export const createUnbreakableQuantity = createAction(
	UnbreakableQuantityActions.CreateUnbreakableQuantity,
	props<ActionRequestPayload<CreateUnbreakableQuantityModel>>()
);

export const unbreakableQuantityCreated = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityCreated
);

export const updateUnbreakableQuantity = createAction(
	UnbreakableQuantityActions.UpdateUnbreakableQuantity,
	props<ActionRequestPayload<UpdateUnbreakableQuantityModel>>()
);

export const unbreakableQuantityUpdated = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityUpdated
);

export const deleteUnbreakableQuantity = createAction(
	UnbreakableQuantityActions.DeleteUnbreakableQuantity,
	props<ActionRequestPayload<number>>()
);

export const unbreakableQuantityDeleted = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityDeleted
);

export const loadUnbreakableQuantityDetailsByNdc = createAction(
	UnbreakableQuantityActions.LoadUnbreakableQuantityDetailsByNdc,
	props<ActionRequestPayload<string>>()
);

export const unbreakableQuantityDetailsByNdcLoaded = createAction(
	UnbreakableQuantityActions.UnbreakableQuantityDetailsByNdcLoaded,
	props<ActionResponsePayload<UnbreakableQuantityMedispanDataModel>>()
);

export const errorOccurred = createAction(
	UnbreakableQuantityActions.ErrorOccurred
);
