import { NonPatientFilterModel } from 'app/models/api/non-patient-filter-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { createAction, props } from '@ngrx/store';
import { NonPatientExclusionModel } from 'app/models/api/non-patient-exclusion-model';
import { NonPatientExclusionSearchModel } from 'app/models/api/non-patient-exclusion-search-model';
import { CreateNonPatientExclusionModel } from 'app/models/api/create-non-patient-exclusion-model';
import { SearchResponse } from 'app/models/api/search-response';
import { UpdateNonPatientExclusionModel } from 'app/models/api/update-non-patient-exclusion-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum NonPatientExclusionActions {
	LoadNonPatientExclusion = '[NonPatientExclusion] LoadNonPatientExclusion',
	NonPatientExclusionLoaded = '[NonPatientExclusion] NonPatientExclusionLoaded',

	PreLoadNonPatientExclusion = '[NonPatientExclusion] PreLoadNonPatientExclusion',
	NonPatientExclusionPreLoaded = '[NonPatientExclusion] NonPatientExclusionPreLoaded',
	PreLoadNonPatientExclusionNotFound = '[NonPatientExclusion] PreLoadNonPatientExclusionNotFound',

	LoadNonPatientExclusionById = '[NonPatientExclusion] LoadNonPatientExclusionById',
	NonPatientExclusionNotFound = '[NonPatientExclusion] NonPatientExclusionNotFound',
	NonPatientExclusionByIdLoaded = '[NonPatientExclusion] NonPatientExclusionByIdLoaded',

	CreateNonPatientExclusion = '[NonPatientExclusion] CreateNonPatientExclusion',
	NonPatientExclusionCreated = '[NonPatientExclusion] NonPatientExclusionCreated',

	UpdateNonPatientExclusion = '[NonPatientExclusion] UpdateNonPatientExclusion',
	NonPatientExclusionUpdated = '[NonPatientExclusion] NonPatientExclusionUpdated',

	DeleteNonPatientExclusion = '[NonPatientExclusion] DeleteNonPatientExclusion',
	NonPatientExclusionDeleted = '[NonPatientExclusion] NonPatientExclusionDeleted',

	ErrorOccurred = '[NonPatientExclusion] ErrorOccurred'
}

export const loadNonPatientExclusionList = createAction(
	NonPatientExclusionActions.LoadNonPatientExclusion,
	props<ActionRequestPayload<FilterSearchRequest<NonPatientFilterModel>>>()
);

export const nonPatientExclusionLoaded = createAction(
	NonPatientExclusionActions.NonPatientExclusionLoaded,
	props<
		ActionResponsePayload<SearchResponse<NonPatientExclusionSearchModel>>
	>()
);

export const preLoadNonPatientExclusionById = createAction(
	NonPatientExclusionActions.PreLoadNonPatientExclusion,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadNonPatientExclusionNotFound = createAction(
	NonPatientExclusionActions.PreLoadNonPatientExclusionNotFound
);

export const nonPatientExclusionByIdPreLoaded = createAction(
	NonPatientExclusionActions.NonPatientExclusionPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<NonPatientExclusionModel>>>()
);

export const loadNonPatientExclusionById = createAction(
	NonPatientExclusionActions.LoadNonPatientExclusionById,
	props<ActionRequestPayload<number>>()
);

export const loadNonPatientExclusionNotFound = createAction(
	NonPatientExclusionActions.NonPatientExclusionNotFound
);

export const nonPatientExclusionByIdLoaded = createAction(
	NonPatientExclusionActions.NonPatientExclusionByIdLoaded,
	props<ActionResponsePayload<NonPatientExclusionModel>>()
);

export const createNonPatientExclusion = createAction(
	NonPatientExclusionActions.CreateNonPatientExclusion,
	props<ActionRequestPayload<CreateNonPatientExclusionModel>>()
);

export const nonPatientExclusionCreated = createAction(
	NonPatientExclusionActions.NonPatientExclusionCreated
);

export const updateNonPatientExclusion = createAction(
	NonPatientExclusionActions.UpdateNonPatientExclusion,
	props<ActionRequestPayload<UpdateNonPatientExclusionModel>>()
);

export const nonPatientExclusionUpdated = createAction(
	NonPatientExclusionActions.NonPatientExclusionUpdated
);

export const deleteNonPatientExclusion = createAction(
	NonPatientExclusionActions.DeleteNonPatientExclusion,
	props<ActionRequestPayload<number>>()
);

export const nonPatientExclusionDeleted = createAction(
	NonPatientExclusionActions.NonPatientExclusionDeleted
);

export const errorOccurred = createAction(
	NonPatientExclusionActions.ErrorOccurred
);
