import { NgIf } from '@angular/common';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { Subject } from 'rxjs';

@Component({
	selector:      'app-empty-layout',
	templateUrl:   './empty.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone:    true,
	imports:       [FuseLoadingBarComponent, NgIf, RouterOutlet]
})
export class EmptyLayoutComponent implements OnDestroy {
	private unsubscribeAll: Subject<unknown> = new Subject<unknown>();

	constructor() {}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this.unsubscribeAll.next(null);
		this.unsubscribeAll.complete();
	}
}
