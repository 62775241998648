import { inject, Injectable, NgZone } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IdentityProviders } from 'app/common/identity-providers';
import { AccountRoutes } from 'app/common/routes';
import { catchAppError } from 'app/common/utils/utils.error';
import { isNullOrUndefined } from 'app/common/utils/utils.object';
import { PreviousRoute } from 'app/models/dto/previous-route';
import { selectAccountStatus } from 'app/store/account/account.selectors';
import { environment } from 'environments/environment';
import { filter, merge, of, pairwise, switchMap, tap } from 'rxjs';

import {
	refreshToken,
	sendCurrentUserChangePasswordRequest,
	setRedirectUrl,
	signOut as legacySignOut,
	updatePreviousRoute
} from '../account/account.actions';
import { AuthStatus, IAccountState } from '../account/account.state';
import { signIn, signOut as auth0SignOut } from '../auth0/auth0.actions';
import { IAuth0State } from '../auth0/auth0.state';

import { AuthProxyActions } from './auth-proxy.actions';

@Injectable()
export class AuthProxyEffects {
	appInitialized$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthProxyActions.AppInitialized),
				switchMap(() =>
					environment.identityProvider === IdentityProviders.Auth0
						? of()
						: merge(
								this.previousRouteUpdating$,
								this.tokenRefreshing$
							)
				)
			),
		{ dispatch: false }
	);

	authenticate$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthProxyActions.Authenticate),
				tap(({ redirectUrl }) => {
					if (
						environment.identityProvider === IdentityProviders.Auth0
					) {
						this.auth0Store.dispatch(signIn({ redirectUrl }));
					} else {
						this.store.dispatch(setRedirectUrl({ redirectUrl }));

						this.router
							.navigate([AccountRoutes.SignIn])
							.catch(catchAppError);
					}
				})
			),
		{ dispatch: false }
	);

	signOut$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthProxyActions.SignOut),
				tap(() => {
					if (
						environment.identityProvider === IdentityProviders.Auth0
					) {
						this.auth0Store.dispatch(auth0SignOut());
					} else {
						this.store.dispatch(legacySignOut());

						this.router
							.navigate([AccountRoutes.SignIn])
							.catch(catchAppError);
					}
				})
			),
		{ dispatch: false }
	);

	resetCurrentUserPassword$ = createEffect(
		() =>
			this.actions.pipe(
				ofType(AuthProxyActions.ResetCurrentUserPassword),
				tap(payload => {
					if (
						environment.identityProvider === IdentityProviders.Auth0
					) {
						this.store.dispatch(
							sendCurrentUserChangePasswordRequest(payload)
						);
					} else {
						this.router
							.navigate([AccountRoutes.ConfirmPasswordChange])
							.catch(catchAppError);
					}
				})
			),
		{ dispatch: false }
	);

	private readonly timeoutAtMilliseconds: number;
	private readonly auth0Store?: Store<IAuth0State>;
	private readonly previousRouteUpdating$ = this.router.events.pipe(
		filter((event: RoutesRecognized) => event instanceof RoutesRecognized),
		pairwise(),
		tap(([{ url }]: RoutesRecognized[]) =>
			this.store.dispatch(
				updatePreviousRoute({ route: url } as PreviousRoute)
			)
		)
	);

	private readonly tokenRefreshing$ = this.store
		.select(selectAccountStatus)
		.pipe(
			tap((authStatus: AuthStatus) => {
				this.stopTokenRefreshing();

				if (authStatus === AuthStatus.Authenticated) {
					this.startTokenRefreshing();
				}
			})
		);

	private refreshTokenInterval: NodeJS.Timeout;

	constructor(
		private readonly store: Store<IAccountState>,
		private readonly actions: Actions,
		private readonly router: Router,
		private readonly ngZone: NgZone
	) {
		this.timeoutAtMilliseconds =
			environment.tokenExpirationInSeconds * 1000;

		if (environment.identityProvider === IdentityProviders.Auth0) {
			this.auth0Store = inject(Store<IAuth0State>);
		}
	}

	private startTokenRefreshing(): void {
		this.refreshTokenInterval = this.ngZone.runOutsideAngular(() =>
			setInterval(() => {
				this.store.dispatch(refreshToken());
			}, this.timeoutAtMilliseconds)
		);
	}

	private stopTokenRefreshing(): void {
		if (!isNullOrUndefined(this.refreshTokenInterval)) {
			clearInterval(this.refreshTokenInterval);
		}
	}
}
