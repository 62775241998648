import { AccountModel } from 'app/models/api/account-model';
import { AuthorizedAccountModel } from 'app/models/api/authorized-account-model';
import { GeneratedFileModel } from 'app/models/api/generated-file-model';
import { NotificationsModel } from 'app/models/api/notifications-model';
import { PermissionModel } from 'app/models/api/permission-model';
import { SignInModel } from 'app/models/api/sign-in-model';

export interface IAccountState {
	loadingRequestCount: number;
	status: AuthStatus;
	signInData: SignInModel;
	account: AuthorizedAccountModel;
	token: string;
	refreshToken: string;
	permissions: PermissionModel[];
	redirectUrl: string;
	currentUser: AccountModel;
	passwordResetUrl: string;
	historyEmail: string;
	previousRoute: string;
	generatedFile: GeneratedFileModel;
	notifications: NotificationsModel;
	disabled: boolean;
}

export const initialAccountState: IAccountState = {
	loadingRequestCount: 0,
	status:              null,
	signInData:          null,
	account:             null,
	token:               null,
	refreshToken:        null,
	permissions:         null,
	redirectUrl:         null,
	currentUser:         null,
	passwordResetUrl:    null,
	historyEmail:        null,
	previousRoute:       null,
	notifications:       null,
	generatedFile:       null,
	disabled:            false
};

export enum AuthStatus {
	Authenticated = 1,
	SignedOut = 2
}
