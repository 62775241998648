import { Injectable } from '@angular/core';
import { CurrentUserPasswordResetVerifyCodeModel } from 'app/models/api/current-user-password-reset-verify-code-model';
import { ForgotPasswordRequest } from 'app/models/api/forgot-password-request';
import { PasswordResetConfirmationModel } from 'app/models/api/password-reset-confirmation-model';
import { SendPasswordResetVerifyCodeModel } from 'app/models/api/send-password-reset-verify-code-model';
import { PasswordResetResultModel } from 'app/models/dto/password-reset-result-model';
import { Observable } from 'rxjs';

import { AuthRoutes, PasswordRoutes } from './api-routes';
import { HttpApiService } from './base/http-api.service';

@Injectable()
export class PasswordApiService {
	private readonly rootRoute: string = `${AuthRoutes.Root}/${PasswordRoutes.Root}`;

	constructor(protected httpApiService: HttpApiService) {}

	//from user profile

	public sendAuthenticatedUserPasswordResetVerifyCode(
		model: CurrentUserPasswordResetVerifyCodeModel,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.post<void>(
			`${this.rootRoute}/${PasswordRoutes.SendAuthenticatedUserPasswordResetVerifyCode}`,
			model,
			cancellationSubject
		);
	}

	public confirmCurrentUserPasswordReset(
		model: PasswordResetConfirmationModel,
		cancellationSubject: Observable<void>
	): Observable<PasswordResetResultModel> {
		return this.httpApiService.post(
			`${this.rootRoute}/${PasswordRoutes.ConfirmAuthenticatedUserPasswordReset}`,
			model,
			cancellationSubject
		);
	}

	public sendCurrentUserPasswordChangeRequest(
		userId: number,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.post(
			`${this.rootRoute}/${PasswordRoutes.CurrentUserPasswordChangeRequest}?userId=${userId}`,
			null,
			cancellationSubject
		);
	}

	//from login page

	public sendForgotPasswordRequest(
		model: ForgotPasswordRequest,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.post<void>(
			`${this.rootRoute}/${PasswordRoutes.SendForgotPasswordEmail}`,
			model,
			cancellationSubject
		);
	}

	public sendPasswordResetVerifyCode(
		model: SendPasswordResetVerifyCodeModel,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.post<void>(
			`${this.rootRoute}/${PasswordRoutes.SendPasswordResetVerifyCode}`,
			model,
			cancellationSubject
		);
	}

	public confirmPasswordReset(
		model: PasswordResetConfirmationModel,
		cancellationSubject: Observable<void>
	): Observable<PasswordResetResultModel> {
		return this.httpApiService.post(
			`${this.rootRoute}/${PasswordRoutes.ConfirmPasswordReset}`,
			model,
			cancellationSubject
		);
	}

	//for admin

	public sendUserPasswordChangeRequest(
		userId: string,
		cancellationSubject: Observable<void>
	): Observable<void> {
		return this.httpApiService.post(
			`${this.rootRoute}/${PasswordRoutes.PasswordChangeRequest}?userId=${userId}`,
			cancellationSubject
		);
	}
}
