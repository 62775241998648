import { createAction, props } from '@ngrx/store';
import { CustomerDetailsModel } from 'app/models/api/customer-details-model';
import { CustomerOptionFilterModel } from 'app/models/api/customer-option-filter-model';
import { CreateCustomerRetentionRateModel } from 'app/models/api/create-customer-retention-rate-model';
import { CustomerRetentionRateModel } from 'app/models/api/customer-retention-rate-model';
import { CustomerRetentionRateSearchModel } from 'app/models/api/customer-retention-rate-search-model';
import { CustomerRetentionRateFilterModel } from 'app/models/api/customer-retention-rate-filter-model';
import { UpdateCustomerRetentionRateModel } from 'app/models/api/update-customer-retention-rate-model';
import { CustomerSearchModel } from 'app/models/api/customer-search-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { CustomerLocationsImportFileModel } from 'app/models/dto/customer-locations-import-file-model';
import { ImportFileRecordsResult } from 'app/models/api/import-file-records-result';
import { CustomerNoteSearchModel } from 'app/models/api/customer-note-search-model';
import { CustomerNoteFilterModel } from 'app/models/api/customer-note-filter-model';
import { CustomerFileMappingConfigModel } from 'app/models/api/customer-file-mapping-config-model';
import { CustomerNoteHistoryModel } from 'app/models/api/customer-note-history-model';
import { CustomerNoteHistorySearchFilterModel } from 'app/models/api/customer-note-history-search-filter-model';
import { CreateCustomerNoteModel } from 'app/models/api/create-customer-note-model';
import { UpdateCustomerNoteModel } from 'app/models/api/update-customer-note-model';

export enum CustomerActions {
	LoadCustomers = '[Customer] LoadCustomers',
	CustomersLoaded = '[Customer] CustomersLoaded',

	PreloadCustomer = '[Customer] PreloadCustomer',
	PreloadedCustomerNotFound = '[Customer] PreloadedCustomerNotFound',
	CustomerPreloaded = '[Customer] CustomerPreloaded',

	LoadCustomer = '[Customer] LoadCustomer',
	CustomerNotFound = '[Customer] CustomerNotFound',
	CustomerLoaded = '[Customer] CustomerLoaded',

	UpdateCustomer = '[Customer] UpdateCustomer',
	CustomerUpdated = '[Customer] CustomerUpdated',

	CreateCustomer = '[Customer] CreateCustomer',
	CustomerCreated = '[Customer] CustomerCreated',

	CheckPharmacyIdExist = '[Customer] CheckPharmacyIdExist',
	PharmacyIdExistChecked = '[Customer] PharmacyIdExistChecked',

	CheckPharmacyIdExcluded = '[Customer] CheckPharmacyIdExcluded',
	PharmacyIdExcludedChecked = '[Customer] PharmacyIdExcludedChecked',

	LoadCustomerOptions = '[Customer] LoadCustomerOptions',
	CustomerOptionsLoaded = '[Customer] CustomerOptionsLoaded',

	LoadRetentionRates = '[Customer] LoadRetentionRates',
	RetentionRatesLoaded = '[Customer] RetentionRatesLoaded',

	PreloadRetentionRate = '[Customer] PreloadRetentionRate',
	RetentionRatePreloaded = '[Customer] RetentionRatePreloaded',
	LoadRetentionRate = '[Customer] LoadRetentionRate',
	RetentionRateLoaded = '[Customer] RetentionRateLoaded',
	RetentionRateNotFound = '[Customer] RetentionRateNotFound',

	CreateRetentionRate = '[Customer] CreateRetentionRate',
	RetentionRateCreated = '[Customer] RetentionRateCreated',

	UpdateRetentionRate = '[Customer] UpdateRetentionRate',
	RetentionRateUpdated = '[Customer] RetentionRateUpdated',

	DeleteRetentionRate = '[Customer] DeleteRetentionRate',
	RetentionRateDeleted = '[Customer] RetentionRateDeleted',

	ImportLocationsFile = '[Customer] ImportLocationsFile',
	LocationsFileImported = '[Customer] LocationsFileImported',
	LocationsFileImporting = '[Customer] LocationsFileImporting',

	SetCurrentCustomerFormValue = '[Customer] SetCurrentCustomerFormValue',

	LoadDownstreamClientIdOptions = '[Customer] LoadDownstreamClientIdOptions',
	DownstreamClientIdOptionsLoaded = '[Customer] DownstreamClientIdOptionsLoaded',

	// Notes
	LoadNotes = '[Customer] LoadNotes',
	NotesLoaded = '[Customer] NotesLoaded',

	CreateNote = '[Customer] CreateNote',
	NoteCreated = '[Customer] NotesCreated',

	UpdateNote = '[Customer] UpdateNote',
	NoteUpdated = '[Customer] NoteUpdated',

	RestoreNote = '[Customer] RestoreNote',
	NoteRestored = '[Customer] NoteRestored',

	DeleteNote = '[Customer] DeleteNote',
	NoteDeleted = '[Customer] NoteDeleted',

	LoadNoteHistory = '[Customer] LoadNoteHistory',
	NoteHistoryLoaded = '[Customer] NoteHistoryLoaded',

	LoadCustomerFileMappingConfig = '[Customer] LoadCustomerFileMappingConfig',
	CustomerFileMappingConfigLoaded = '[Customer] CustomerFileMappingConfigLoaded',

	StartCustomerLocationsFileGeneration = '[Customer] StartCustomerLocationsFileGeneration',
	CustomerLocationsFileGenerationStarted = '[Customer] CustomerLocationsFileGenerationStarted',

	ErrorOccurred = '[Customer] ErrorOccurred'
}

export const loadCustomers = createAction(
	CustomerActions.LoadCustomers,
	props<ActionRequestPayload<SearchRequest>>()
);

export const customersLoaded = createAction(
	CustomerActions.CustomersLoaded,
	props<ActionResponsePayload<SearchResponse<CustomerSearchModel>>>()
);

export const preloadCustomer = createAction(
	CustomerActions.PreloadCustomer,
	props<ActionRequestPayload<number>>()
);

export const preloadedCustomerNotFound = createAction(
	CustomerActions.PreloadedCustomerNotFound
);

export const customerPreloaded = createAction(
	CustomerActions.CustomerPreloaded,
	props<ActionResponsePayload<CustomerDetailsModel>>()
);

export const loadCustomer = createAction(
	CustomerActions.LoadCustomer,
	props<ActionRequestPayload<number>>()
);

export const customerNotFound = createAction(CustomerActions.CustomerNotFound);

export const customerLoaded = createAction(
	CustomerActions.CustomerLoaded,
	props<ActionResponsePayload<CustomerDetailsModel>>()
);

export const updateCustomer = createAction(
	CustomerActions.UpdateCustomer,
	props<ActionRequestPayload<CustomerDetailsModel>>()
);

export const customerUpdated = createAction(CustomerActions.CustomerUpdated);

export const createCustomer = createAction(
	CustomerActions.CreateCustomer,
	props<ActionRequestPayload<CustomerDetailsModel>>()
);

export const customerCreated = createAction(
	CustomerActions.CustomerCreated,
	props<ActionResponsePayload<number>>()
);

export const checkPharmacyIdExist = createAction(
	CustomerActions.CheckPharmacyIdExist,
	props<ActionRequestPayload<string>>()
);

export const pharmacyIdExistChecked = createAction(
	CustomerActions.PharmacyIdExistChecked,
	props<ActionResponsePayload<boolean>>()
);

export const checkPharmacyIdExcluded = createAction(
	CustomerActions.CheckPharmacyIdExcluded,
	props<ActionRequestPayload<string>>()
);

export const pharmacyIdExcludedChecked = createAction(
	CustomerActions.PharmacyIdExcludedChecked,
	props<ActionResponsePayload<boolean>>()
);

export const loadCustomerOptions = createAction(
	CustomerActions.LoadCustomerOptions,
	props<ActionRequestPayload<CustomerOptionFilterModel>>()
);

export const customerOptionsLoaded = createAction(
	CustomerActions.CustomerOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadRetentionRates = createAction(
	CustomerActions.LoadRetentionRates,
	props<
		ActionResponsePayload<
			FilterSearchRequest<CustomerRetentionRateFilterModel>
		>
	>()
);

export const retentionRatesLoaded = createAction(
	CustomerActions.RetentionRatesLoaded,
	props<
		ActionResponsePayload<SearchResponse<CustomerRetentionRateSearchModel>>
	>()
);

export const preloadRetentionRate = createAction(
	CustomerActions.PreloadRetentionRate,
	props<ActionResponsePayload<number>>()
);

export const retentionRatePreloaded = createAction(
	CustomerActions.RetentionRatePreloaded,
	props<ActionResponsePayload<CustomerRetentionRateModel>>()
);

export const loadRetentionRate = createAction(
	CustomerActions.LoadRetentionRate,
	props<ActionResponsePayload<number>>()
);

export const retentionRateLoaded = createAction(
	CustomerActions.RetentionRateLoaded,
	props<ActionResponsePayload<CustomerRetentionRateModel>>()
);

export const retentionRateNotFound = createAction(
	CustomerActions.RetentionRateNotFound
);

export const createRetentionRate = createAction(
	CustomerActions.CreateRetentionRate,
	props<ActionResponsePayload<CreateCustomerRetentionRateModel>>()
);

export const retentionRateCreated = createAction(
	CustomerActions.RetentionRateCreated,
	props<ActionResponsePayload<CustomerRetentionRateModel>>()
);

export const updateRetentionRate = createAction(
	CustomerActions.UpdateRetentionRate,
	props<ActionResponsePayload<UpdateCustomerRetentionRateModel>>()
);

export const retentionRateUpdated = createAction(
	CustomerActions.RetentionRateUpdated,
	props<ActionResponsePayload<CustomerRetentionRateModel>>()
);

export const deleteRetentionRate = createAction(
	CustomerActions.DeleteRetentionRate,
	props<ActionResponsePayload<number>>()
);

export const retentionRateDeleted = createAction(
	CustomerActions.RetentionRateDeleted,
	props<ActionResponsePayload<number>>()
);

export const importLocationsFile = createAction(
	CustomerActions.ImportLocationsFile,
	props<ActionRequestPayload<CustomerLocationsImportFileModel>>()
);

export const locationsFileImported = createAction(
	CustomerActions.LocationsFileImported,
	props<ActionResponsePayload<ImportFileRecordsResult>>()
);

export const setCurrentCustomerFormValue = createAction(
	CustomerActions.SetCurrentCustomerFormValue,
	props<{ currentCustomerFormValue: CustomerDetailsModel }>()
);

export const loadDownstreamClientIdOptions = createAction(
	CustomerActions.LoadDownstreamClientIdOptions,
	props<ActionRequestPayload<void>>()
);

export const downstreamClientIdOptionsLoaded = createAction(
	CustomerActions.DownstreamClientIdOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadNotes = createAction(
	CustomerActions.LoadNotes,
	props<ActionRequestPayload<CustomerNoteFilterModel>>()
);

export const notesLoaded = createAction(
	CustomerActions.NotesLoaded,
	props<ActionResponsePayload<CustomerNoteSearchModel[]>>()
);

export const createNote = createAction(
	CustomerActions.CreateNote,
	props<ActionRequestPayload<CreateCustomerNoteModel>>()
);

export const noteCreated = createAction(CustomerActions.NoteCreated);

export const updateNote = createAction(
	CustomerActions.UpdateNote,
	props<ActionRequestPayload<UpdateCustomerNoteModel>>()
);

export const noteUpdated = createAction(CustomerActions.NoteUpdated);

export const restoreNote = createAction(
	CustomerActions.RestoreNote,
	props<ActionRequestPayload<number>>()
);

export const noteRestored = createAction(CustomerActions.NoteRestored);

export const deleteNote = createAction(
	CustomerActions.DeleteNote,
	props<ActionRequestPayload<number>>()
);

export const noteDeleted = createAction(CustomerActions.NoteDeleted);

export const loadNoteHistory = createAction(
	CustomerActions.LoadNoteHistory,
	props<
		ActionRequestPayload<
			FilterSearchRequest<CustomerNoteHistorySearchFilterModel>
		>
	>()
);

export const noteHistoryLoaded = createAction(
	CustomerActions.NoteHistoryLoaded,
	props<ActionResponsePayload<SearchResponse<CustomerNoteHistoryModel>>>()
);

export const loadCustomerFileMappingConfig = createAction(
	CustomerActions.LoadCustomerFileMappingConfig,
	props<ActionRequestPayload<number>>()
);

export const customerFileMappingConfigLoaded = createAction(
	CustomerActions.CustomerFileMappingConfigLoaded,
	props<ActionResponsePayload<CustomerFileMappingConfigModel>>()
);

export const startCustomerLocationsFileGeneration = createAction(
	CustomerActions.StartCustomerLocationsFileGeneration,
	props<ActionRequestPayload<void>>()
);

export const customerLocationsFileGenerationStarted = createAction(
	CustomerActions.CustomerLocationsFileGenerationStarted
);

export const errorOccurred = createAction(CustomerActions.ErrorOccurred);
