import { createAction, props } from '@ngrx/store';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { LineOfBusinessSearchModel } from 'app/models/api/line-of-business-search-model';
import { CreateLineOfBusinessModel } from 'app/models/api/create-line-of-business-model';
import { UpdateLineOfBusinessModel } from 'app/models/api/update-line-of-business-model';
import { LineOfBusinessModel } from 'app/models/api/line-of-business-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { LineOfBusinessOptionsFilterModel } from 'app/models/api/line-of-business-options-filter-model';

export enum LineOfBusinessActions {
	LoadLinesOfBusiness = '[LineOfBusiness] LoadLinesOfBusiness',
	LinesOfBusinessLoaded = '[LineOfBusiness] LinesOfBusinessLoaded',

	PreLoadLineOfBusiness = '[LineOfBusiness] PreLoadLineOfBusiness',
	PreLoadLineOfBusinessNotFound = '[LineOfBusiness] PreLoadLineOfBusinessNotFound',
	LineOfBusinessPreLoaded = '[LineOfBusiness] LineOfBusinessPreLoaded',

	LoadLineOfBusinessById = '[LineOfBusiness] LoadLineOfBusinessById',
	LoadLineOfBusinessNotFound = '[LineOfBusiness] LoadLineOfBusinessNotFound',
	LineOfBusinessByIdLoaded = '[LineOfBusiness] LineOfBusinessByIdLoaded',

	CreateLineOfBusiness = '[LineOfBusiness] CreateLineOfBusiness',
	LineOfBusinessCreated = '[LineOfBusiness] LineOfBusinessCreated',

	UpdateLineOfBusiness = '[LineOfBusiness] UpdateLineOfBusiness',
	LineOfBusinessUpdated = '[LineOfBusiness] LineOfBusinessUpdated',

	DeleteLineOfBusiness = '[LineOfBusiness] DeleteLineOfBusiness',
	LineOfBusinessDeleted = '[LineOfBusiness] LineOfBusinessDeleted',

	LoadLineOfBusinessOptions = '[LineOfBusiness] LoadLineOfBusinessOptions',
	LineOfBusinessOptionsLoaded = '[LineOfBusiness] LineOfBusinessOptionsLoaded',

	ErrorOccurred = '[LinesOfBusiness] ErrorOccurred'
}

export const loadLinesOfBusiness = createAction(
	LineOfBusinessActions.LoadLinesOfBusiness,
	props<ActionRequestPayload<SearchRequest>>()
);

export const linesOfBusinessLoaded = createAction(
	LineOfBusinessActions.LinesOfBusinessLoaded,
	props<ActionResponsePayload<SearchResponse<LineOfBusinessSearchModel>>>()
);

export const preLoadLineOfBusiness = createAction(
	LineOfBusinessActions.PreLoadLineOfBusiness,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadLineOfBusinessNotFound = createAction(
	LineOfBusinessActions.PreLoadLineOfBusinessNotFound
);

export const lineOfBusinessPreLoaded = createAction(
	LineOfBusinessActions.LineOfBusinessPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<LineOfBusinessModel>>>()
);

export const loadLineOfBusinessById = createAction(
	LineOfBusinessActions.LoadLineOfBusinessById,
	props<ActionRequestPayload<number>>()
);

export const loadLineOfBusinessNotFound = createAction(
	LineOfBusinessActions.LoadLineOfBusinessNotFound
);

export const lineOfBusinessByIdLoaded = createAction(
	LineOfBusinessActions.LineOfBusinessByIdLoaded,
	props<ActionResponsePayload<LineOfBusinessModel>>()
);

export const createLineOfBusiness = createAction(
	LineOfBusinessActions.CreateLineOfBusiness,
	props<ActionRequestPayload<CreateLineOfBusinessModel>>()
);

export const lineOfBusinessCreated = createAction(
	LineOfBusinessActions.LineOfBusinessCreated
);

export const updateLineOfBusiness = createAction(
	LineOfBusinessActions.UpdateLineOfBusiness,
	props<ActionRequestPayload<UpdateLineOfBusinessModel>>()
);

export const lineOfBusinessUpdated = createAction(
	LineOfBusinessActions.LineOfBusinessUpdated
);

export const deleteLineOfBusiness = createAction(
	LineOfBusinessActions.DeleteLineOfBusiness,
	props<ActionRequestPayload<number>>()
);

export const lineOfBusinessDeleted = createAction(
	LineOfBusinessActions.LineOfBusinessDeleted
);

export const loadLineOfBusinessOptions = createAction(
	LineOfBusinessActions.LoadLineOfBusinessOptions,
	props<ActionRequestPayload<LineOfBusinessOptionsFilterModel>>()
);

export const lineOfBusinessOptionsLoaded = createAction(
	LineOfBusinessActions.LineOfBusinessOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const errorOccurred = createAction(LineOfBusinessActions.ErrorOccurred);
