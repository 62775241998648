<!-- Item wrapper -->
<div
	class="fuse-vertical-navigation-item-wrapper"
	[class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
	[ngClass]="item.classes?.wrapper"
>
	<!-- Item with an internal link -->
	<ng-container *ngIf="item.link && !item.externalLink && !item.function && !item.disabled">
		<a
			class="fuse-vertical-navigation-item"
			[ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
			[routerLink]="[item.link]"
			[fragment]="item.fragment ?? null"
			[preserveFragment]="item.preserveFragment ?? false"
			[queryParams]="item.queryParams ?? null"
			[queryParamsHandling]="item.queryParamsHandling ?? null"
			[routerLinkActive]="'fuse-vertical-navigation-item-active'"
			[routerLinkActiveOptions]="isActiveMatchOptions"
			[matTooltip]="item.tooltip || ''"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</a>
	</ng-container>

	<!-- Item with an external link -->
	<ng-container *ngIf="item.link && item.externalLink && !item.function && !item.disabled">
		<a
			class="fuse-vertical-navigation-item"
			[href]="item.link"
			[target]="item.target || '_self'"
			[matTooltip]="item.tooltip || ''"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</a>
	</ng-container>

	<!-- Item with a function -->
	<ng-container *ngIf="!item.link && item.function && !item.disabled">
		<div
			class="fuse-vertical-navigation-item"
			[ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
			[matTooltip]="item.tooltip || ''"
			(click)="item.function(item)"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</div>
	</ng-container>

	<!-- Item with an internal link and function -->
	<ng-container *ngIf="item.link && !item.externalLink && item.function && !item.disabled">
		<a
			class="fuse-vertical-navigation-item"
			[ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
			[routerLink]="[item.link]"
			[fragment]="item.fragment ?? null"
			[preserveFragment]="item.preserveFragment ?? false"
			[queryParams]="item.queryParams ?? null"
			[queryParamsHandling]="item.queryParamsHandling ?? null"
			[routerLinkActive]="'fuse-vertical-navigation-item-active'"
			[routerLinkActiveOptions]="isActiveMatchOptions"
			[matTooltip]="item.tooltip || ''"
			(click)="item.function(item)"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</a>
	</ng-container>

	<!-- Item with an external link and function -->
	<ng-container *ngIf="item.link && item.externalLink && item.function && !item.disabled">
		<a
			class="fuse-vertical-navigation-item"
			[href]="item.link"
			[target]="item.target || '_self'"
			[matTooltip]="item.tooltip || ''"
			(click)="item.function(item)"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</a>
	</ng-container>

	<!-- Item with a no link and no function -->
	<ng-container *ngIf="!item.link && !item.function && !item.disabled">
		<div
			class="fuse-vertical-navigation-item"
			[ngClass]="{ 'fuse-vertical-navigation-item-active-forced': item.active }"
			[matTooltip]="item.tooltip || ''"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</div>
	</ng-container>

	<!-- Item is disabled -->
	<ng-container *ngIf="item.disabled">
		<div
			class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled"
			[matTooltip]="item.tooltip || ''"
		>
			<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
		</div>
	</ng-container>
</div>

<!-- Item template -->
<ng-template #itemTemplate>
	<!-- Icon -->
	<ng-container *ngIf="item.icon">
		<mat-icon
			fontSet="fas"
			[fontIcon]="item.icon"
			class="fuse-vertical-navigation-item-icon text-lg"
		></mat-icon>
	</ng-container>

	<!-- Title & Subtitle -->
	<div class="fuse-vertical-navigation-item-title-wrapper">
		<div class="fuse-vertical-navigation-item-title">
			<span [ngClass]="item.classes?.title">
				{{ item.title }}
			</span>
		</div>
		<ng-container *ngIf="item.subtitle">
			<div class="fuse-vertical-navigation-item-subtitle">
				<span [ngClass]="item.classes?.subtitle">
					{{ item.subtitle }}
				</span>
			</div>
		</ng-container>
	</div>

	<!-- Badge -->
	<ng-container *ngIf="item.badge">
		<div class="fuse-vertical-navigation-item-badge">
			<div
				class="fuse-vertical-navigation-item-badge-content"
				[ngClass]="item.badge.classes"
			>
				{{ item.badge.title }}
			</div>
		</div>
	</ng-container>
</ng-template>
