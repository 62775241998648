import { PreloadingStrategy, Route } from '@angular/router';
import {
	delay,
	finalize,
	Observable,
	of,
	Subject,
	switchMap,
	takeWhile
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuthenticated } from 'app/store/auth-proxy/auth-proxy.selectors';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticatedPreloadingStrategy implements PreloadingStrategy {
	private isPreloadInProgress: boolean = false;
	private preloadSubject: Subject<void> = new Subject();
	private preload$: Observable<void> = this.preloadSubject.asObservable();

	constructor(private readonly store: Store) {}

	preload(
		route: Route,
		load: () => Observable<unknown>
	): Observable<unknown> {
		return this.store.select(selectIsAuthenticated).pipe(
			takeWhile(isAuthenticated => !isAuthenticated, true),
			switchMap(isAuthenticated => {
				if (!isAuthenticated) {
					return of(null);
				}

				const nextPreloadSubject: Subject<void> = new Subject();

				this.preload$.pipe(delay(100)).subscribe(() =>
					requestIdleCallback(() =>
						load()
							.pipe(finalize(() => nextPreloadSubject.next()))
							.subscribe()
					)
				);

				this.preload$ = nextPreloadSubject.asObservable();

				if (!this.isPreloadInProgress) {
					this.preloadSubject.next();
				}

				this.preloadSubject = nextPreloadSubject;

				this.isPreloadInProgress = true;

				return of(null);
			})
		);
	}
}
