import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserRoutes } from 'app/common/routes';
import { catchAppError } from 'app/common/utils/utils.error';
import { selectIsAuthenticated } from 'app/store/auth-proxy/auth-proxy.selectors';
import { AuthProxyState } from 'app/store/auth-proxy/auth-proxy.state';
import { map, tap } from 'rxjs';

export const AnonymousGuard: CanActivateFn = () => {
	const router = inject(Router);

	return inject(Store<AuthProxyState>)
		.select(selectIsAuthenticated)
		.pipe(
			map(isAuthenticated => !isAuthenticated),
			tap(isPermitted => {
				if (!isPermitted) {
					void router
						.navigate([UserRoutes.Root])
						.catch(catchAppError);
				}
			})
		);
};
