import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationModel } from 'app/models/dto/notification-model';
import { SnackBarComponent } from 'app/shared/components/snack-bar/snack-bar.component';

import {
	defaultNotificationDuration,
	defaultNotificationType
} from '../common/app-constants';

@Injectable({ providedIn: 'root' })
export class ToastService {
	constructor(private readonly snackBar: MatSnackBar) {}

	public show(notificationData: NotificationModel): void {
		const config = this.getConfig(notificationData);

		this.snackBar.openFromComponent(SnackBarComponent, config);
	}

	private getConfig(notificationData: NotificationModel): MatSnackBarConfig {
		return {
			data: {
				...notificationData,
				type: notificationData.type ?? defaultNotificationType
			},
			duration:           notificationData.duration ?? defaultNotificationDuration,
			horizontalPosition: 'center',
			verticalPosition:   'top',
			panelClass:         ['mat-toolbar', 'bg-transparent']
		};
	}
}
