import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import {
	HubEvents,
	NotificationEvents
} from 'app/common/notification.constants';
import { filterNullOrUndefined } from 'app/extensions/pipe-operators';
import { selectToken } from 'app/store/account/account.selectors';
import { IAccountState } from 'app/store/account/account.state';
import { environment } from 'environments/environment';
import { filter, first, from, Observable } from 'rxjs';
import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';
import { isNullOrUndefined } from 'app/common/utils/utils.object';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	public disabled: boolean = true;
	private enableLogging: boolean = true;
	private hubConnection: signalR.HubConnection;
	private startedConnection: Promise<HubConnectionState>;

	private subscriptionCache: NotificationSubscriptionModel[];

	constructor(private readonly store: Store<IAccountState>) {
		this.subscriptionCache = [];
		this.store
			.select(selectToken)
			.pipe(filterNullOrUndefined(), first())
			.subscribe(token => {
				if (this.disabled) {
					return;
				}

				this.hubConnection = new signalR.HubConnectionBuilder()
					.withUrl(environment.signalrHubServiceUrl + '/hub', {
						accessTokenFactory: () => token
					})
					.withAutomaticReconnect({
						nextRetryDelayInMilliseconds: context =>
							this.retryPolicy(context.previousRetryCount)
					})
					.build();

				this.startedConnection = this.connect();
			});
	}

	public subscribe(groups: NotificationSubscriptionModel[]): void {
		this.subscriptionCache = this.subscriptionCache.concat(groups);
		this.afterConnection(() => {
			if (this.enableLogging) {
				console.log(HubEvents.Subscribe, groups);
			}

			this.hubConnection.send(HubEvents.Subscribe, groups);
		});
	}

	public unsubscribe(groups: NotificationSubscriptionModel[]): void {
		groups.forEach(
			c =>
				(this.subscriptionCache = this.subscriptionCache.filter(
					x =>
						x.group !== c.group
						&& (isNullOrUndefined(c.entityId)
							|| x.entityId !== c.entityId)
				))
		);

		this.afterConnection(() => {
			if (this.enableLogging) {
				console.log(HubEvents.UnSubscribe, groups);
			}

			this.hubConnection.send(HubEvents.UnSubscribe, groups);
		});
	}

	public onEvent<T>(name: NotificationEvents): Observable<T> {
		return new Observable<T>(observer => {
			this.hubConnection.on(name, (notification: T) => {
				if (this.enableLogging) {
					console.log(name, notification);
				}

				return observer.next(notification);
			});
		});
	}

	private afterConnection(callback: () => void): void {
		if (this.disabled) {
			return;
		}

		from(this.startedConnection)
			.pipe(filter(x => x == HubConnectionState.Connected))
			.subscribe(() => callback());
	}

	private connect(): Promise<HubConnectionState> {
		return this.hubConnection
			.start()
			.then(() => {
				console.log('Connected to notification hub');

				this.hubConnection.onreconnected(() => {
					console.log('Reconnect to notification hub');

					if (this.enableLogging) {
						console.log(
							HubEvents.Subscribe,
							this.subscriptionCache
						);
					}

					this.hubConnection.send(
						HubEvents.Subscribe,
						this.subscriptionCache
					);
				});

				return HubConnectionState.Connected;
			})
			.catch(error => {
				console.log('Error while establishing connection', error);

				return HubConnectionState.Disconnected;
			});
	}

	private retryPolicy(previousRetryCount: number): number {
		switch (previousRetryCount) {
			case 0:
				return 1000;

			case 1:
				return 2000;

			case 2:

			case 3:

			case 4:

			case 5:
				return 5000;

			default:
				return 30000;
		}
	}
}
