import { createAction, props } from '@ngrx/store';
import { CreateRebateGroupFormularyModel } from 'app/models/api/create-rebate-group-formulary-model';
import { CreateRebateGroupModel } from 'app/models/api/create-rebate-group-model';
import { CreateRebateGroupRuleModel } from 'app/models/api/create-rebate-group-rule-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { ListOptionModel } from 'app/models/api/list-option-model';
import { RebateGroupFormularyFilterModel } from 'app/models/api/rebate-group-formulary-filter-model';
import { RebateGroupFormularyModel } from 'app/models/api/rebate-group-formulary-model';
import { RebateGroupFormularySearchModel } from 'app/models/api/rebate-group-formulary-search-model';
import { RebateGroupModel } from 'app/models/api/rebate-group-model';
import { RebateGroupRuleFilterModel } from 'app/models/api/rebate-group-rule-filter-model';
import { RebateGroupRuleModel } from 'app/models/api/rebate-group-rule-model';
import { RebateGroupRuleSearchModel } from 'app/models/api/rebate-group-rule-search-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { UpdateRebateGroupFormularyModel } from 'app/models/api/update-rebate-group-formulary-model';
import { UpdateRebateGroupModel } from 'app/models/api/update-rebate-group-model';
import { UpdateRebateGroupRuleModel } from 'app/models/api/update-rebate-group-rule-model';
import { IPreLoadModel } from 'app/models/dto/pre-load-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum RebateGroupActions {
	LoadRebateGroup = '[RebateGroup] LoadRebateGroup',
	RebateGroupLoaded = '[RebateGroup] RebateGroupLoaded',

	PreLoadRebateGroup = '[RebateGroup] PreLoadRebateGroup',
	RebateGroupPreLoaded = '[RebateGroup] RebateGroupPreLoaded',
	PreLoadRebateGroupNotFound = '[RebateGroup] PreLoadRebateGroupNotFound',

	LoadRebateGroupById = '[RebateGroup] LoadRebateGroupById',
	LoadRebateGroupNotFound = '[RebateGroup] LoadRebateGroupNotFound',
	RebateGroupByIdLoaded = '[RebateGroup] RebateGroupByIdLoaded',

	CreateRebateGroup = '[RebateGroup] CreateRebateGroup',
	RebateGroupCreated = '[RebateGroup] RebateGroupCreated',

	UpdateRebateGroup = '[RebateGroup] UpdateRebateGroup',
	RebateGroupUpdated = '[RebateGroup] RebateGroupUpdated',

	DeleteRebateGroup = '[RebateGroup] DeleteRebateGroup',
	RebateGroupDeleted = '[RebateGroup] RebateGroupDeleted',

	LoadRebateGroupOptions = '[RebateGroup] LoadRebateGroupOptions',
	RebateGroupOptionsLoaded = '[RebateGroup] RebateGroupOptionsLoaded',

	LoadRebateGroupFormularies = '[RebateGroup] LoadRebateGroupFormularies',
	RebateGroupFormulariesLoaded = '[RebateGroup] RebateGroupFormulariesLoaded',

	LoadRebateGroupFormulary = '[RebateGroup] LoadRebateGroupFormulary',
	LoadRebateGroupFormularyNotFound = '[RebateGroup] LoadRebateGroupFormularyNotFound',
	RebateGroupFormularyLoaded = '[RebateGroup] RebateGroupFormularyLoaded',

	PreLoadRebateGroupFormulary = '[RebateGroup] PreLoadRebateGroupFormulary',
	RebateGroupFormularyPreLoaded = '[RebateGroup] RebateGroupFormularyPreLoaded',
	PreLoadRebateGroupFormularyNotFound = '[RebateGroup] PreLoadRebateGroupFormularyNotFound',

	CreateRebateGroupFormulary = '[RebateGroup] CreateRebateGroupFormulary',
	RebateGroupFormularyCreated = '[RebateGroup] RebateGroupFormularyCreated',

	UpdateRebateGroupFormulary = '[RebateGroup] UpdateRebateGroupFormulary',
	RebateGroupFormularyUpdated = '[RebateGroup] RebateGroupFormularyUpdated',

	DeleteRebateGroupFormulary = '[RebateGroup] DeleteRebateGroupFormulary',
	RebateGroupFormularyDeleted = '[RebateGroup] RebateGroupFormularyDeleted',

	LoadRebateGroupRules = '[RebateGroup] LoadRebateGroupRules',
	RebateGroupRulesLoaded = '[RebateGroup] RebateGroupRulesLoaded',

	LoadRebateGroupRule = '[RebateGroup] LoadRebateGroupRule',
	LoadRebateGroupRuleNotFound = '[RebateGroup] LoadRebateGroupRuleNotFound',
	RebateGroupRuleLoaded = '[RebateGroup] RebateGroupRuleLoaded',

	PreLoadRebateGroupRule = '[RebateGroup] PreLoadRebateGroupRule',
	RebateGroupRulePreLoaded = '[RebateGroup] RebateGroupRulePreLoaded',
	PreLoadRebateGroupRuleNotFound = '[RebateGroup] PreLoadRebateGroupRuleNotFound',

	CreateRebateGroupRule = '[RebateGroup] CreateRebateGroupRule',
	RebateGroupRuleCreated = '[RebateGroup] RebateGroupRuleCreated',

	UpdateRebateGroupRule = '[RebateGroup] UpdateRebateGroupRule',
	RebateGroupRuleUpdated = '[RebateGroup] RebateGroupRuleUpdated',

	DeleteRebateGroupRule = '[RebateGroup] DeleteRebateGroupRule',
	RebateGroupRuleDeleted = '[RebateGroup] RebateGroupRuleDeleted',

	ErrorOccurred = '[RebateGroup] ErrorOccurred'
}

export const loadRebateGroupList = createAction(
	RebateGroupActions.LoadRebateGroup,
	props<ActionRequestPayload<SearchRequest>>()
);

export const rebateGroupLoaded = createAction(
	RebateGroupActions.RebateGroupLoaded,
	props<ActionResponsePayload<SearchResponse<RebateGroupModel>>>()
);

export const preLoadRebateGroupById = createAction(
	RebateGroupActions.PreLoadRebateGroup,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadRebateGroupNotFound = createAction(
	RebateGroupActions.PreLoadRebateGroupNotFound
);

export const rebateGroupByIdPreLoaded = createAction(
	RebateGroupActions.RebateGroupPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<RebateGroupModel>>>()
);

export const loadRebateGroupById = createAction(
	RebateGroupActions.LoadRebateGroupById,
	props<ActionRequestPayload<number>>()
);

export const loadRebateGroupNotFound = createAction(
	RebateGroupActions.LoadRebateGroupNotFound
);

export const rebateGroupByIdLoaded = createAction(
	RebateGroupActions.RebateGroupByIdLoaded,
	props<ActionResponsePayload<RebateGroupModel>>()
);

export const createRebateGroup = createAction(
	RebateGroupActions.CreateRebateGroup,
	props<ActionRequestPayload<CreateRebateGroupModel>>()
);

export const rebateGroupCreated = createAction(
	RebateGroupActions.RebateGroupCreated
);

export const updateRebateGroup = createAction(
	RebateGroupActions.UpdateRebateGroup,
	props<ActionRequestPayload<UpdateRebateGroupModel>>()
);

export const rebateGroupUpdated = createAction(
	RebateGroupActions.RebateGroupUpdated
);

export const deleteRebateGroup = createAction(
	RebateGroupActions.DeleteRebateGroup,
	props<ActionRequestPayload<number>>()
);

export const rebateGroupDeleted = createAction(
	RebateGroupActions.RebateGroupDeleted
);

export const loadRebateGroupOptions = createAction(
	RebateGroupActions.LoadRebateGroupOptions,
	props<ActionRequestPayload<void>>()
);

export const rebateGroupOptionsLoaded = createAction(
	RebateGroupActions.RebateGroupOptionsLoaded,
	props<ActionResponsePayload<ListOptionModel<number>[]>>()
);

export const loadRebateGroupFormularies = createAction(
	RebateGroupActions.LoadRebateGroupFormularies,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateGroupFormularyFilterModel>
		>
	>()
);

export const rebateGroupFormulariesLoaded = createAction(
	RebateGroupActions.RebateGroupFormulariesLoaded,
	props<
		ActionResponsePayload<SearchResponse<RebateGroupFormularySearchModel>>
	>()
);

export const loadRebateGroupFormulary = createAction(
	RebateGroupActions.LoadRebateGroupFormulary,
	props<ActionRequestPayload<number>>()
);

export const loadRebateGroupFormularyNotFound = createAction(
	RebateGroupActions.LoadRebateGroupFormularyNotFound
);

export const rebateGroupFormularyLoaded = createAction(
	RebateGroupActions.RebateGroupFormularyLoaded,
	props<ActionResponsePayload<RebateGroupFormularyModel>>()
);

export const preLoadRebateGroupFormulary = createAction(
	RebateGroupActions.PreLoadRebateGroupFormulary,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadRebateGroupFormularyNotFound = createAction(
	RebateGroupActions.PreLoadRebateGroupFormularyNotFound
);

export const rebateGroupFormularyPreLoaded = createAction(
	RebateGroupActions.RebateGroupFormularyPreLoaded,
	props<ActionResponsePayload<IPreLoadModel<RebateGroupFormularyModel>>>()
);

export const createRebateGroupFormulary = createAction(
	RebateGroupActions.CreateRebateGroupFormulary,
	props<ActionRequestPayload<CreateRebateGroupFormularyModel>>()
);

export const rebateGroupFormularyCreated = createAction(
	RebateGroupActions.RebateGroupFormularyCreated,
	props<ActionResponsePayload<number>>()
);

export const updateRebateGroupFormulary = createAction(
	RebateGroupActions.UpdateRebateGroupFormulary,
	props<ActionRequestPayload<UpdateRebateGroupFormularyModel>>()
);

export const rebateGroupFormularyUpdated = createAction(
	RebateGroupActions.RebateGroupFormularyUpdated,
	props<ActionResponsePayload<number>>()
);

export const deleteRebateGroupFormulary = createAction(
	RebateGroupActions.DeleteRebateGroupFormulary,
	props<ActionRequestPayload<number>>()
);

export const rebateGroupFormularyDeleted = createAction(
	RebateGroupActions.RebateGroupFormularyDeleted
);

export const loadRebateGroupRules = createAction(
	RebateGroupActions.LoadRebateGroupRules,
	props<
		ActionRequestPayload<FilterSearchRequest<RebateGroupRuleFilterModel>>
	>()
);

export const rebateGroupRulesLoaded = createAction(
	RebateGroupActions.RebateGroupRulesLoaded,
	props<ActionResponsePayload<SearchResponse<RebateGroupRuleSearchModel>>>()
);

export const loadRebateGroupRule = createAction(
	RebateGroupActions.LoadRebateGroupRule,
	props<ActionRequestPayload<number>>()
);

export const loadRebateGroupRuleNotFound = createAction(
	RebateGroupActions.LoadRebateGroupRuleNotFound
);

export const rebateGroupRuleLoaded = createAction(
	RebateGroupActions.RebateGroupRuleLoaded,
	props<ActionResponsePayload<RebateGroupRuleModel>>()
);

export const preLoadRebateGroupRule = createAction(
	RebateGroupActions.PreLoadRebateGroupRule,
	props<ActionRequestPayload<IPreLoadModel<number>>>()
);

export const preLoadRebateGroupRuleNotFound = createAction(
	RebateGroupActions.PreLoadRebateGroupRuleNotFound
);

export const rebateGroupRulePreLoaded = createAction(
	RebateGroupActions.RebateGroupRulePreLoaded,
	props<ActionResponsePayload<IPreLoadModel<RebateGroupRuleModel>>>()
);

export const createRebateGroupRule = createAction(
	RebateGroupActions.CreateRebateGroupRule,
	props<ActionRequestPayload<CreateRebateGroupRuleModel>>()
);

export const rebateGroupRuleCreated = createAction(
	RebateGroupActions.RebateGroupRuleCreated,
	props<ActionResponsePayload<number>>()
);

export const updateRebateGroupRule = createAction(
	RebateGroupActions.UpdateRebateGroupRule,
	props<ActionRequestPayload<UpdateRebateGroupRuleModel>>()
);

export const rebateGroupRuleUpdated = createAction(
	RebateGroupActions.RebateGroupRuleUpdated,
	props<ActionResponsePayload<number>>()
);

export const deleteRebateGroupRule = createAction(
	RebateGroupActions.DeleteRebateGroupRule,
	props<ActionRequestPayload<number>>()
);

export const rebateGroupRuleDeleted = createAction(
	RebateGroupActions.RebateGroupRuleDeleted
);

export const errorOccurred = createAction(RebateGroupActions.ErrorOccurred);
