import { createAction, props } from '@ngrx/store';
import { SearchResponse } from 'app/models/api/search-response';
import { CreateRebateAggregatorNdcRateModel } from 'app/models/api/create-rebate-aggregator-ndc-rate-model';
import { RebateAggregatorNdcRateModel } from 'app/models/api/rebate-aggregator-ndc-rate-model';
import { UpdateRebateAggregatorNdcRateModel } from 'app/models/api/update-rebate-aggregator-ndc-rate-model';
import { RebateAggregatorNdcRatesSearchFilterModel } from 'app/models/api/rebate-aggregator-ndc-rates-search-filter-model';
import { RebateAggregationNdcRateHistorySearchFilterModel } from 'app/models/api/rebate-aggregation-ndc-rate-history-search-filter-model';
import { RebateAggregatorNdcRateHistoryModel } from 'app/models/api/rebate-aggregator-ndc-rate-history-model';
import { RebateAggregatorNdcRatePreloadResponseModel } from 'app/models/dto/rebate-aggregator-ndc-rate-preload-response-model';
import { RebateAggregatorNdcRatePreloadRequestModel } from 'app/models/dto/rebate-aggregator-ndc-rate-preload-request-model';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { ActionResponsePayload } from 'app/store/action-response-payload';
import { RebateAggregatorNdcRateImportResultModel } from 'app/models/api/rebate-aggregator-ndc-rate-import-result-model';
import { RebateAggregatorNdcRatesImportModel } from 'app/models/dto/rebate-aggregator-ndc-rates-import-model';

export enum RebateAggregatorNdcRatesActions {
	LoadRebatesAggregatorNdcRates = '[RebateAggregatorNdcRates] LoadRebatesAggregatorsNdcRates',
	RebatesAggregatorNdcRatesLoaded = '[RebateAggregatorNdcRates] RebatesAggregatorsNdcRatesLoaded',

	CreateRebateAggregatorNdcRate = '[RebateAggregatorNdcRates] CreateRebateAggregatorNdcRate',
	RebateAggregatorNdcRateCreated = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateCreated',

	UpdateRebateAggregatorNdcRate = '[RebateAggregatorNdcRates] UpdateRebateAggregatorNdcRate',
	RebateAggregatorNdcRateUpdated = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateUpdated',

	AddRebateAggregatorNdcRate = '[RebateAggregatorNdcRates] AddRebateAggregatorNdcRate',
	RebateAggregatorNdcRateAdded = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateAdded',

	PreloadRebateAggregatorNdcRate = '[RebateAggregatorNdcRates] PreloadRebateAggregatorNdcRates',
	PreloadedRebateAggregatorNdcRateNotFound = '[RebateAggregatorNdcRates] PreloadedRebateAggregatorNdcRatesNotFound',
	RebateAggregatorNdcRatePreloaded = '[RebateAggregatorNdcRates] RebateAggregatorNdcRatesPreloaded',

	LoadRebateAggregatorNdcRate = '[RebateAggregatorNdcRates] LoadRebateAggregatorNdcRate',
	RebateAggregatorNdcRateNotFound = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateNotFound',
	RebateAggregatorNdcRateLoaded = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateLoaded',

	LoadRebateAggregatorNdcRateHistory = '[RebateAggregatorNdcRates] LoadRebateAggregatorNdcRateHistory',
	RebateAggregatorNdcRateHistoryLoaded = '[RebateAggregatorNdcRates] RebateAggregatorNdcRateHistoryLoaded',

	StartRebateAggregatorNdcRatesFileGeneration = '[RebateAggregatorNdcRates] StartRebateAggregatorNdcRatesFileGeneration',
	RebateAggregatorNdcRatesFileGenerationStarted = '[RebateAggregatorNdcRates] RebateAggregatorNdcRatesFileGenerationStarted',

	ImportRebateAggregatorNdcRatesFile = '[RebateAggregatorNdcRates] ImportRebateAggregatorNdcRatesFile',
	RebateAggregatorNdcRatesFileImporting = '[RebateAggregatorNdcRates] RebateAggregatorNdcRatesFileImporting',
	RebateAggregatorNdcRatesFileImported = '[RebateAggregatorNdcRates] RebateAggregatorNdcRatesFileImported',

	ErrorOccurred = '[RebateAggregators] ErrorOccurred'
}

export const loadRebatesAggregatorNdcRates = createAction(
	RebateAggregatorNdcRatesActions.LoadRebatesAggregatorNdcRates,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateAggregatorNdcRatesSearchFilterModel>
		>
	>()
);

export const rebatesAggregatorNdcRatesLoaded = createAction(
	RebateAggregatorNdcRatesActions.RebatesAggregatorNdcRatesLoaded,
	props<ActionResponsePayload<SearchResponse<RebateAggregatorNdcRateModel>>>()
);

export const createRebateAggregatorNdcRate = createAction(
	RebateAggregatorNdcRatesActions.CreateRebateAggregatorNdcRate,
	props<ActionRequestPayload<CreateRebateAggregatorNdcRateModel>>()
);

export const rebateAggregatorNdcRateCreated = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateCreated
);

export const updateRebateAggregatorNdcRate = createAction(
	RebateAggregatorNdcRatesActions.UpdateRebateAggregatorNdcRate,
	props<ActionRequestPayload<UpdateRebateAggregatorNdcRateModel>>()
);

export const rebateAggregatorNdcRateUpdated = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateUpdated
);

export const addRebateAggregatorNdcRate = createAction(
	RebateAggregatorNdcRatesActions.AddRebateAggregatorNdcRate,
	props<ActionRequestPayload<UpdateRebateAggregatorNdcRateModel>>()
);

export const rebateAggregatorNdcRateAdded = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateAdded
);

export const loadRebateAggregatorNdcRate = createAction(
	RebateAggregatorNdcRatesActions.LoadRebateAggregatorNdcRate,
	props<ActionRequestPayload<number>>()
);

export const rebateAggregatorNdcRateNotFound = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateNotFound
);

export const rebateAggregatorNdcRateLoaded = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateLoaded,
	props<ActionResponsePayload<RebateAggregatorNdcRateModel>>()
);

export const loadRebateAggregatorNdcRateHistory = createAction(
	RebateAggregatorNdcRatesActions.LoadRebateAggregatorNdcRateHistory,
	props<
		ActionRequestPayload<
			FilterSearchRequest<RebateAggregationNdcRateHistorySearchFilterModel>
		>
	>()
);

export const rebateAggregatorNdcRateHistoryLoaded = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRateHistoryLoaded,
	props<
		ActionResponsePayload<
			SearchResponse<RebateAggregatorNdcRateHistoryModel>
		>
	>()
);

export const preloadRebateAggregatorNdcRate = createAction(
	RebateAggregatorNdcRatesActions.PreloadRebateAggregatorNdcRate,
	props<ActionRequestPayload<RebateAggregatorNdcRatePreloadRequestModel>>()
);

export const preloadedRebateAggregatorNdcRateNotFound = createAction(
	RebateAggregatorNdcRatesActions.PreloadedRebateAggregatorNdcRateNotFound
);

export const rebateAggregatorNdcRatePreloaded = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatePreloaded,
	props<ActionResponsePayload<RebateAggregatorNdcRatePreloadResponseModel>>()
);

export const startRebateAggregatorNdcRatesFileGeneration = createAction(
	RebateAggregatorNdcRatesActions.StartRebateAggregatorNdcRatesFileGeneration,
	props<ActionRequestPayload<RebateAggregatorNdcRatesSearchFilterModel>>()
);

export const rebateAggregatorNdcRatesFileGenerationStarted = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatesFileGenerationStarted
);

export const importRebateAggregatorNdcRatesFile = createAction(
	RebateAggregatorNdcRatesActions.ImportRebateAggregatorNdcRatesFile,
	props<ActionRequestPayload<RebateAggregatorNdcRatesImportModel>>()
);

export const rebateAggregatorNdcRatesFileImporting = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatesFileImporting,
	props<ActionResponsePayload<number>>()
);

export const rebateAggregatorNdcRatesFileImported = createAction(
	RebateAggregatorNdcRatesActions.RebateAggregatorNdcRatesFileImported,
	props<ActionResponsePayload<RebateAggregatorNdcRateImportResultModel>>()
);

export const errorOccurred = createAction(
	RebateAggregatorNdcRatesActions.ErrorOccurred
);
