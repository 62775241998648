import { PermissionModel } from 'app/models/api/permission-model';

export interface IAuth0State {
	isLoading: boolean;
	isAuthenticated: boolean;
	permissions: PermissionModel[];
	error: AuthenticationError;
}

export const initialAuth0State: IAuth0State = {
	isLoading:       true,
	isAuthenticated: false,
	permissions:     [],
	error:           null
};

export enum AuthenticationError {
	UserBlocked = 'user is blocked',
	PasswordExpired = 'Password expired',
	LoginRequired = 'Login required',
	UninvitedUser = 'The user is uninvited'
}
