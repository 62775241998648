import { createAction, props } from '@ngrx/store';
import { FilterSearchRequest } from 'app/models/api/filter-search-request';
import { PermissionScopeModel } from 'app/models/api/permission-scope-model';
import { RoleDetailsModel } from 'app/models/api/role-details-model';
import { RoleModel } from 'app/models/api/role-model';
import { RoleNameModel } from 'app/models/api/role-name-model';
import { SearchRequest } from 'app/models/api/search-request';
import { SearchResponse } from 'app/models/api/search-response';
import { UserFilterModel } from 'app/models/api/user-filter-model';
import { UserListModel } from 'app/models/api/user-list-model';
import { UserModel } from 'app/models/api/user-model';
import { CreateUserModel } from 'app/models/api/create-user-model';
import { UpdateUserModel } from 'app/models/api/update-user-model';
import { CreateRoleDetailsModel } from 'app/models/api/create-role-details-model';
import { UpdateRoleDetailsModel } from 'app/models/api/update-role-details-model';

import { ActionRequestPayload } from '../action-request-payload';
import { ActionResponsePayload } from '../action-response-payload';

export enum UserActions {
	LoadUsers = '[User] LoadUsers',
	UsersLoaded = '[User] UsersLoaded',

	PreloadEditableUser = '[User] PreloadEditableUser',
	EditableUserPreloaded = '[User] EditableUserPreloaded',
	PreloadedUserNotFound = '[User] PreloadedUserNotFound',

	LoadEditableUser = '[User] LoadEditableUser',
	EditableUserLoaded = '[User] EditableUserLoaded',
	EditableUserNotFound = '[User] EditableUserNotFound',

	CreateEditableUser = '[User] CreateEditableUser',
	EditableUserCreated = '[User] EditableUserCreated',

	UpdateEditableUser = '[User] UpdateEditableUser',
	EditableUserUpdated = '[User] EditableUserUpdated',

	DeleteUser = '[User] DeleteUser',
	UserDeleted = '[User] UserDeleted',

	LoadRoles = '[User] LoadRoles',
	RolesLoaded = '[User] RolesLoaded',

	LoadRolesNames = '[User] LoadRolesNames',
	RolesNamesLoaded = '[User] RolesNamesLoaded',

	PreloadRole = '[User] PreloadRole',
	RolePreloaded = '[User] RolePreloaded',
	PreloadedRoleNotFound = '[User] PreloadedRoleNotFound',

	LoadRole = '[User] LoadRole',
	RoleLoaded = '[User] RoleLoaded',
	RoleNotFound = '[User] RoleNotFound',

	CreateRole = '[User] CreateRole',
	RoleCreated = '[User] RoleCreated',

	UpdateRole = '[User] UpdateRole',
	RoleUpdated = '[User] RoleUpdated',

	LoadPermissions = '[User] LoadPermissions',
	PermissionsLoaded = '[User] PermissionsLoaded',

	ErrorOccurred = '[User] ErrorOccurred'
}

export const loadUsers = createAction(
	UserActions.LoadUsers,
	props<ActionRequestPayload<FilterSearchRequest<UserFilterModel>>>()
);

export const usersLoaded = createAction(
	UserActions.UsersLoaded,
	props<ActionResponsePayload<SearchResponse<UserListModel>>>()
);

export const preloadEditableUser = createAction(
	UserActions.PreloadEditableUser,
	props<ActionRequestPayload<number>>()
);

export const editableUserPreloaded = createAction(
	UserActions.EditableUserPreloaded,
	props<ActionResponsePayload<UserModel>>()
);

export const preloadedUserNotFound = createAction(
	UserActions.PreloadedUserNotFound
);

export const loadEditableUser = createAction(
	UserActions.LoadEditableUser,
	props<ActionRequestPayload<number>>()
);

export const editableUserLoaded = createAction(
	UserActions.EditableUserLoaded,
	props<ActionResponsePayload<UserModel>>()
);

export const editableUserNotFound = createAction(
	UserActions.EditableUserNotFound
);

export const createEditableUser = createAction(
	UserActions.CreateEditableUser,
	props<ActionRequestPayload<CreateUserModel>>()
);

export const editableUserCreated = createAction(
	UserActions.EditableUserCreated
);

export const updateEditableUser = createAction(
	UserActions.UpdateEditableUser,
	props<ActionRequestPayload<UpdateUserModel>>()
);

export const editableUserUpdated = createAction(
	UserActions.EditableUserUpdated
);

export const deleteUser = createAction(
	UserActions.DeleteUser,
	props<ActionRequestPayload<number>>()
);

export const userDeleted = createAction(UserActions.UserDeleted);

export const loadRoles = createAction(
	UserActions.LoadRoles,
	props<ActionRequestPayload<SearchRequest>>()
);

export const rolesLoaded = createAction(
	UserActions.RolesLoaded,
	props<ActionResponsePayload<SearchResponse<RoleModel>>>()
);

export const loadRolesNames = createAction(
	UserActions.LoadRolesNames,
	props<ActionResponsePayload<void>>()
);

export const rolesNamesLoaded = createAction(
	UserActions.RolesNamesLoaded,
	props<ActionResponsePayload<RoleNameModel[]>>()
);

export const preloadRole = createAction(
	UserActions.PreloadRole,
	props<ActionRequestPayload<number>>()
);

export const preloadedRoleNotFound = createAction(
	UserActions.PreloadedRoleNotFound
);

export const rolePreloaded = createAction(
	UserActions.RolePreloaded,
	props<ActionResponsePayload<RoleDetailsModel>>()
);

export const loadRole = createAction(
	UserActions.LoadRole,
	props<ActionRequestPayload<number>>()
);

export const roleNotFound = createAction(UserActions.RoleNotFound);

export const roleLoaded = createAction(
	UserActions.RoleLoaded,
	props<ActionResponsePayload<RoleDetailsModel>>()
);

export const createRole = createAction(
	UserActions.CreateRole,
	props<ActionRequestPayload<CreateRoleDetailsModel>>()
);

export const roleCreated = createAction(UserActions.RoleCreated);

export const updateRole = createAction(
	UserActions.UpdateRole,
	props<ActionRequestPayload<UpdateRoleDetailsModel>>()
);

export const roleUpdated = createAction(UserActions.RoleUpdated);

export const loadPermissions = createAction(
	UserActions.LoadPermissions,
	props<ActionRequestPayload<null>>()
);

export const permissionsLoaded = createAction(
	UserActions.PermissionsLoaded,
	props<ActionResponsePayload<PermissionScopeModel[]>>()
);

export const errorOccurred = createAction(UserActions.ErrorOccurred);
