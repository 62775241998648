import camelcase from 'camelcase';

import { locale } from '../app-constants';

import { isNullOrUndefined } from './utils.object';

export const isEmptyOrWhiteSpace = (value: string): boolean =>
	isNullOrUndefined(value) || value.trim() === '';

export const toCamelCase = (value: string): string =>
	camelcase(value, {
		preserveConsecutiveUppercase: true,
		locale
	});

export const escapeRegex = (value: string): string =>
	value?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const removeSpecials = (value: string): string =>
	value?.replace(/[^a-zA-Z0-9а-яА-Я ]/g, '');

export const toHierarchyString = (value: string): string =>
	removeSpecials(value)?.replace(/\s/g, '')?.toUpperCase();
