import { NotificationSubscriptionModel } from 'app/models/dto/notification-subscription';
import { NotificationGroups } from 'app/common/notification.constants';

export const buildEntityGroups = (
	groups: NotificationGroups[],
	entityId: number
): NotificationSubscriptionModel[] => {
	return groups.map(g => ({ group: g, entityId }));
};

export const buildGroups = (
	groups: NotificationGroups[]
): NotificationSubscriptionModel[] => {
	return groups.map(g => ({ group: g, entityId: null }));
};
