import { Injectable, OnDestroy } from '@angular/core';
import { copyObject } from 'app/common/utils/utils.object';
import { ActionRequestPayload } from 'app/store/action-request-payload';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export abstract class BaseApiComponent implements OnDestroy {
	isLoading = false;

	protected cancellationObservable: Observable<void> = new Observable(e => {
		this.emitter = e;
	});

	private emitter: Subscriber<void>;

	protected constructor() {}

	ngOnDestroy(): void {
		this.emitter?.next();
		this.emitter?.complete();
	}

	protected createRequestPayload<T>(data?: T): ActionRequestPayload<T> {
		return new ActionRequestPayload(
			data && copyObject(data),
			this.cancellationObservable
		);
	}
}
