import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'app/common/utils/utils.object';

@Injectable({ providedIn: 'root' })
export class IndexDbService {
	private db: IDBDatabase = null;
	private storageName: string = null;

	public openOrCreateDatabaseStorage(
		databaseName: string,
		storageName: string
	): Promise<string> {
		return new Promise((resolve, reject) => {
			this.storageName = storageName;

			const request = window.indexedDB.open(databaseName, 1);

			request.onerror = (): void => {
				reject('Error loading database.');
			};

			request.onsuccess = (): void => {
				window.indexedDB.databases().then(databases => {
					const isDatabaseExist = databases
						.map(db => db.name)
						.includes(databaseName);

					if (isDatabaseExist) {
						this.db = request.result;
						resolve('Database exist and initialized.');
					}
				});
			};

			request.onupgradeneeded = (): void => {
				const db = request.result;

				db.onerror = (): void => {
					reject('Error loading database.');
				};

				if (db.objectStoreNames.contains(storageName)) {
					this.db = db;
					resolve(
						`Object Store with name ${storageName} already exist.`
					);
				}

				const store = db.createObjectStore(storageName, {
					keyPath:       'id',
					autoIncrement: true
				});

				store.transaction.oncomplete = (): void => {
					this.db = db;
					resolve('Object store created.');
				};
			};
		});
	}

	public addRecord<T>(obj: T): Promise<string> {
		return new Promise((resolve, reject) => {
			const transaction = this.db.transaction(
				this.storageName,
				'readwrite'
			);

			transaction.oncomplete = (): void => {
				resolve('Add transaction completed.');
			};

			transaction.onerror = (): void => {
				reject(
					`Add transaction not opened due to error: ${transaction.error}`
				);
			};

			const objectStore = transaction.objectStore(this.storageName);
			const objectStoreRequest = objectStore.add(obj);

			objectStoreRequest.onsuccess = (): void => {
				resolve('Add request successful.');
			};

			objectStoreRequest.onerror = (): void => {
				reject('Add request failed.');
			};
		});
	}

	public closeConnection(): void {
		if (isNullOrUndefined(this.db)) {
			return;
		}

		this.db.close();
	}
}
