import { environment } from 'environments/environment';
import { IdentityProviders } from 'app/common/identity-providers';
import { createSelector } from '@ngrx/store';

import {
	selectIsAuthenticated as selectAuth0IsAuthenticated,
	selectPermissions as selectAuth0Permissions,
	selectNavigation as selectAuth0Navigation
} from '../auth0/auth0.selectors';
import {
	selectAccountStatus,
	selectPermissions as selectLegacyPermissions,
	selectNavigation as selectLegacyNavigation
} from '../account/account.selectors';
import { AuthStatus } from '../account/account.state';

export const selectIsAuthenticated =
	environment.identityProvider === IdentityProviders.Auth0
		? selectAuth0IsAuthenticated
		: createSelector(
				selectAccountStatus,
				status => status === AuthStatus.Authenticated
			);

export const selectPermissions =
	environment.identityProvider === IdentityProviders.Auth0
		? selectAuth0Permissions
		: selectLegacyPermissions;

export const selectNavigation =
	environment.identityProvider === IdentityProviders.Auth0
		? selectAuth0Navigation
		: selectLegacyNavigation;
