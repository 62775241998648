export enum PermissionModelTypes {
	Execute = 1,
	Read = 1 << 1,
	Add = 1 << 2,
	Edit = 1 << 3,
	Delete = 1 << 4,
	ReadAdd = Read | Add,
	ReadEdit = Read | Edit,
	ReadDelete = Read | Delete,
	AddEdit = Add | Edit,
	AddDelete = Add | Delete,
	EditDelete = Edit | Delete,
	ReadAddEdit = Read | Add | Edit,
	ReadAddDelete = Read | Add | Delete,
	ReadEditDelete = Read | Edit | Delete,
	AddEditDelete = Add | Edit | Delete,
	ReadAddEditDelete = Read | Add | Edit | Delete
}
